var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',_vm._b({attrs:{"item-key":"id","headers":_vm.headers,"loading":_vm.loading,"items":_vm.CVs,"items-per-page":10,"footer-props":{'items-per-page-text': 'Số Hồ Sơ một trang'},"disable-sort":"","server-items-length":_vm.count,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(items){return [_vm._v(" Hồ sơ thứ "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" trên tổng "+_vm._s(items.itemsLength)+" Hồ sơ ")]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"document-btn text-none text-decoration-underline px-0",attrs:{"color":"primary","plain":""},on:{"click":function($event){return _vm.onDocumentClick(item.id)}}},[_vm._v(" "+_vm._s(_vm._f("getCode")(item))+" ")])]}},{key:"item.department",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getDepartment")(item))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getStudent")(item))+" ")]}},{key:"item.parentPhone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getUserPhone")(item))+" ")]}},{key:"item.scholarshipTransactionTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getTransactionTime")(item))+" ")]}}],null,true)},'v-data-table',this.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }