import Vue from 'vue'
import App from './App.vue'
import moment from 'moment'
import { inputRules } from '@/plugins/rule'
import helpers from '@/helpers/index.js'
import PluginHelper from '@/helpers/PluginHelper'
import utils from '@/plugins/utils'
import dialog from '@/plugins/dialog'
import adminDialog from '@/plugins/dialog'
import alert from '@/plugins/alert'
import loading from '@/plugins/loading'
import VueHtmlToPaper from 'vue-html-to-paper'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import VueMask from 'v-mask'
import VueGtag from 'vue-gtag'
import {get} from 'lodash'

const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=no', 'scrollbars=no'],
  styles: ['@/modules/active-code/PrintActiveCode.css'],
}

Vue.use(VueMask)
Vue.use(
  PluginHelper.create({
    $utils: utils,
    $rules: inputRules,
    $dialog: dialog,
    $adminDialog: adminDialog,
    $alert: alert,
    $loading: loading,
    $baseUrl: process.env.VUE_APP_API_ENDPOINT,
    $moment: moment,
    $helpers: helpers,
    $_get: get,
  })
)
Vue.use(VueHtmlToPaper, options)
Vue.use(VueGtag, {
  config: {
    id: 'G-KV22Y3VJM5'
  },
  includes: [
    { id: 'G-KGL9XLEMEZ' }
  ]
})
Vue.config.productionTip = false
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
