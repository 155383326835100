<template>
  <div>
    <v-row class="d-flex align-center" no-gutters>
      <v-col cols="12" sm="12" xs="12" md="3">
        <div class="section-title px-2">Phát sinh hồ sơ</div>
      </v-col>
      <v-spacer />
      <v-col cols="12" sm="12" xs="12" md="3">
        <RangeDatePicker @change="e => changeDateRange(e, type)" />
      </v-col>
    </v-row>
    <div>
      <div v-if="loading" class="d-flex justify-center">
        <v-progress-circular indeterminate color="primary" />
      </div>
      <LineChart
        v-else
        style="height: 275px; width: 100%; position: 'relative'"
        :chartdata="chartData"
        :options="{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0,
                  stepSize: 10,
                  reverse: false,
                  beginAtZero: true
                }
              }
            ]
          }
        }"
      />
    </div>
  </div>
</template>
<script>
import LineChart from '@/components/chart/LineChart.vue'
import RangeDatePicker from '@/components/basic/RangeDatePicker.vue'
import moment from 'moment'
import {get} from 'lodash'
import {mapActions} from 'vuex'
export default {
  props: {type: String},
  components: {LineChart, RangeDatePicker},
  data: () => ({loading: true, chartData: {}}),
  methods: {
    ...mapActions({fetchStatsByDayRange: 'cv/fetchStatsByDayRange'}),
    async changeDateRange(ranges) {
      this.loading = true
      this.chartData = await this.getLineChartData(ranges[0], ranges[1])
      this.loading = false
    },
    async getLineChartData(startDate, endDate) {
      const res = await this.fetchStatsByDayRange({
        startDate: moment(startDate)
          .startOf('day')
          .toISOString(),
        endDate: moment(endDate)
          .endOf('day')
          .toISOString(),
        type: this.type
      })

      let daysArray = Array.from(Array(moment(endDate).diff(moment(startDate), 'days') + 1).keys())
      daysArray = daysArray.map(index => ({
        display: moment(startDate)
          .add(index, 'days')
          .format('DD/MM/YYYY'),
        sortIdx: moment(startDate)
          .add(index, 'days')
          .format('YYYY-MM-DD')
      }))
      const data = daysArray.map(day => {
        const d = res.find(x => x.date === day.sortIdx)
        return get(d, 'count', 0)
      })
      return {
        labels: daysArray.map(x => x.display),
        datasets: [
          {
            label: 'Số hồ sơ tạo mới',
            backgroundColor: 'rgba(13,71,161,0.75)',
            pointBackgroundColor: '#0D47A1',
            data
          }
        ]
      }
    }
  }
}
</script>
