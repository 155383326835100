<template>
  <div class="pa-6">
    <div class="d-flex justify-space-between align-center">
      <div class="component-title">Tài khoản người dùng và tin nhắn</div>
      <div class="component-title">{{ getCurrentDepartment }}</div>
    </div>
    <div v-show="user.department === 'both'">
      <div class="section-title py-6">
        Báo cáo
      </div>
      <v-row class="d-flex">
        <v-col cols="6">
          <AccountStats ref="account-stats" />
        </v-col>
        <v-col cols="6">
          <SmsStats ref="sms-stats" />
        </v-col>
      </v-row>
    </div>

    <div class="section-title py-6 d-flex" style="width: 100%" v-if="user.department === 'both'">
      <div>Tài khoản người dùng</div>
      <v-spacer />
      <div class="flex-center">
        <ExcelExport />
      </div>
    </div>
    <v-card class="px-6 pt-6 elevation-1 mb-10">
      <UserFilter @onFilterChanged="onFilterChanged" />
    </v-card>

    <v-card class="elevation-1">
      <UserTable />
    </v-card>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import UserTable from '@/modules/user/UserTable.vue'
import UserFilter from '@/modules/user/UserFilter.vue'
import AccountStats from '@/modules/user/user-stats/AccountStats.vue'
import SmsStats from '@/modules/user/user-stats/SmsStats.vue'
import ExcelExport from '@/modules/user/excel-export/index.vue'

export default {
  components: {
    UserTable,
    UserFilter,
    AccountStats,
    SmsStats,
    ExcelExport
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('user', ['users']),
    getCurrentDepartment() {
      if (this.user && this.user.department != 'both') return this.user.department
      return 'Cơ sở 1 và Cơ sở A'
    }
  },
  methods: {
    ...mapActions('user', ['countUsers', 'fetchUsers']),
    async onFilterChanged(data = {}) {
      this.$loading.active = true
      await this.fetchUsers({_limit: 10, _start: 0, ...data})
      await this.countUsers({...data, 'not-clear-request': true})
      this.$loading.active = false
    }
  },
  async created() {
    this.$loading.active = true
    await this.countUsers({})
    this.$refs['account-stats'].refresh({})
    this.$refs['sms-stats'].refresh({})
    this.$loading.active = false
  }
}
</script>

<style scoped>
.component-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #0d47a1;
}
.section-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #212121;
}
</style>
