<template>
  <v-dialog v-model="dialog" persistent scrollable width="800px">
    <v-card>
      <v-card-title class="admin white--text text-uppercase dialog-title">
        Chi tiết tin gửi
        <v-spacer />
        <v-btn dark icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-6">
        <div class="d-flex mb-2">
          <div class="title-wrapper mr-2 subtitle-2">Tiêu đề</div>
          <div style="value-wrapper">{{ $_get(item, 'title', '---') }}</div>
        </div>
        <div class="d-flex mb-2">
          <div class="title-wrapper mr-2 subtitle-2">Nội dung</div>
          <div class="content-wrapper value-wrapper" v-html="$_get(item, 'content')" />
        </div>
        <div class="d-flex mb-2">
          <div class="title-wrapper mr-2 subtitle-2">Tạo lúc</div>
          <div style="value-wrapper">{{ item | getCreatedAt }}</div>
        </div>
        <div class="d-flex mb-2">
          <div class="title-wrapper mr-2 subtitle-2">Gửi đến</div>
          <div style="value-wrapper">{{ item | getReceiver }}</div>
        </div>
        <div class="d-flex mb-2">
          <div class="title-wrapper mr-2 subtitle-2">Tạo bởi</div>
          <div style="value-wrapper">{{ item | getCreatedBy }}</div>
        </div>
        <div class="d-flex">
          <div class="title-wrapper mr-2 subtitle-2">Trạng thái</div>
          <div style="value-wrapper">
            <v-icon color="green" v-if="$_get(item, 'status') === 'success'">mdi-check-circle</v-icon>
            <v-icon color="red" v-else>mdi-close</v-icon>
          </div>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn class="ma-2" color="cancel" @click="closeDialog" outlined>Đóng</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {get} from 'lodash'
import moment from 'moment'
export default {
  data: () => ({dialog: false, item: null}),
  methods: {
    openDialog(item) {
      this.item = item
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
      this.reset()
    },
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this))
    }
  },
  filters: {
    getCreatedBy: item => get(item, 'createdBy.name', 'Hệ thống'),
    getCreatedAt(item) {
      if (item && item.createdAt) return moment(item.createdAt).format('DD/MM/YYYY HH:mm')
      return '---'
    },
    getReceiver(item) {
      switch (get(item, 'receiver')) {
        case 'all':
          return 'Tất cả'
        case 'reg':
          return 'Đã đăng ký'
        case 'reg_cv6':
          return 'Đã đăng ký hồ sơ tuyển sinh 6'
        case 'reg_cv10':
          return 'Đã đăng ký hồ sơ tuyển sinh 10'
        case 'single':
          return `Đối tượng cụ thể (${get(item, 'user.username', '')})`
        default:
          return '--'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .content-wrapper br {
  display: block; /* makes it have a width */
  content: ''; /* clears default height */
  margin-top: 0; /* change this to whatever height you want it */
}
::v-deep .content-wrapper p {
  margin-top: 0;
}
.title-wrapper {
  width: 15%;
}
.value-wrapper {
  width: 84%;
}
</style>
