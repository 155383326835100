<template>
  <div class="pa-6">
    <DocumentDetailDialog :state="dialog" :documentId="selectedDocumentId" @closeDialog="dialog = !dialog" />
    <div class="d-flex justify-space-between align-center mb-6">
      <div class="component-title">Quản lý hồ sơ</div>
      <DocumentExcelExport />
    </div>
    <v-card class="pa-6 elevation-1 mb-6">
      <DocumentFilter @onFilterChanged="onFilterChanged" />
    </v-card>

    <v-card class="elevation-1">
      <DocumentTable ref="documentTable" @onDocumentDetail="onDocumentDetail" />
    </v-card>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import DocumentFilter from './DocumentFilter.vue'
import DocumentTable from './DocumentTable.vue'
import DocumentDetailDialog from './DocumentDetailDialog.vue'
import DocumentExcelExport from './DocumentExcelExport/index.vue'

export default {
  components: {DocumentDetailDialog, DocumentFilter, DocumentTable, DocumentExcelExport},
  data: () => ({selectedDocumentId: '', dialog: false}),
  async mounted() {
    let query = {_sort: 'updatedAt:DESC'}
    if (this.user.department === 'both') query.department_in = ['Cơ sở A', 'Cơ sở 1', 'unset']
    else query.department_in = [this.user.department, 'unset']
    if (!this.user.department || this.user.department !== 'both') query.status_ne = 'disabled'
    await this.countCVs(query)
  },
  computed: {
    ...mapGetters('cv', ['CVs', 'CV']),
    ...mapGetters('auth', ['user', 'isAuthenticated'])
  },
  methods: {
    ...mapActions('cv', ['fetchCVs', 'setCVs', 'countCVs']),
    async onFilterChanged(data) {
      this.$loading.active = true
      await this.fetchCVs({...data, _sort: 'updatedAt:DESC', _start: 0, _limit: 10})
      await this.countCVs(data)
      this.$loading.active = false
    },
    onDocumentDetail(data) {
      this.dialog = true
      this.selectedDocumentId = data
    }
  }
}
</script>

<style scoped>
.component-title {
  color: #0d47a1;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}
</style>
