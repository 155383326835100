/* eslint-disable no-unused-vars */
import alert from '@/plugins/alert'
import {Notification} from '@/plugins/api'
export default {
  namespaced: true,
  state: {
    notis: {},
    count: 0,
    filterParams: {}
  },
  actions: {
    async fetchNotis({commit, state}, options) {
      const filterParams = {...state.filterParams, ...options}
      const notis = await Notification.fetch({...filterParams, _sort: 'createdAt:desc'})
      commit('setNotis', notis)
      commit('changeState', {filterParams})
      return notis
    },
    async countNotis({commit, state}, options) {
      const filterParams = {...state.filterParams, ...options}
      commit('setCount', await Notification.count(filterParams))
    },
    async createNoti({commit}, data) {
      try {
        const newNoti = await Notification.create(data)
        commit('setNoti', newNoti)
        alert.success('Tạo thông báo thành công!')
        return newNoti
      } catch (e) {
        alert.error(e)
      }
    },
    setNotification({commit, state}, noti) {
      commit('setNoti', noti)
    },
    async removeNoti({dispatch}, itemId) {
      await dispatch('removeNoti', itemId)
    },
    setNotis({commit}, notis) {
      commit('setNotis', notis)
    }
  },
  mutations: {
    setNotis(state, notis) {
      state.notis = notis.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [`${currentValue.id}`]: currentValue
        }),
        {}
      )
      console.log(state.notis)
    },
    setNoti(state, noti) {
      state.notis = {
        [noti.id]: noti,
        ...state.notis
      }
    },
    removeNoti(state, id) {
      delete state.noti[id]
      state.noti = {...state.noti}
    },
    setCount(state, count) {
      state.count = count
    }
  },
  getters: {
    notis: state => Object.values(state.notis),
    noti: state => id => state.notis[id],
    count: state => state.count
  }
}
/* eslint-enable no-unused-vars */
