<template>
  <v-data-table
    item-key="id"
    :headers="headers"
    :loading="loading"
    :items="users"
    :items-per-page="10"
    disable-sort
    :footer-props="{'items-per-page-text': 'Số Mã hồ sơ một trang'}"
    v-bind="this.$attrs"
    :server-items-length="count"
    :options.sync="pagination"
  >
    <template v-slot:[`footer.page-text`]="items">
      Tài khoản thứ {{ items.pageStart }} - {{ items.pageStop }} trên tổng {{ items.itemsLength }} Tài khoản
    </template>
    <template v-slot:[`item.username`]="{item}">
      {{ item | getUsername }}
    </template>
    <template v-slot:[`item.created_at`]="{item}">
      {{ item | getCreatedAt }}
    </template>
    <template v-slot:[`item.isConfirmedOTP`]="{item}">
      <v-chip color="success d-flex justify-center" style="width: 120px" v-if="item.isConfirmedOTP" label>
        Đã kích hoạt
      </v-chip>
      <v-chip color="gray d-flex justify-center" style="width: 120px" v-else label>Chưa kích hoạt</v-chip>
    </template>
    <template v-slot:[`item.SMSNum`]="{item}">
      {{ item | getSMSNum }}
    </template>
    <template v-slot:[`item.documents`]="{item}">
      <div class="d-flex align-center" :class="{'justify-center': $_get(item, 'ownedCvs.length', 0) === 0}">
        <div v-if="$_get(item, 'ownedCvs.length', 0) === 0">
          Chưa tạo hồ sơ
        </div>
        <v-chip-group v-else column>
          <v-chip v-for="document in item.ownedCvs" :key="document" color="primary">
            {{ document }}
          </v-chip>
        </v-chip-group>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {get} from 'lodash'
import moment from 'moment'
moment.locale('vi')

export default {
  computed: {
    ...mapGetters('user', ['users', 'count']),
    headers() {
      return [
        {text: 'Số điện thoại', value: 'username', width: '90'},
        {text: 'Ngày tạo tài khoản', value: 'created_at', width: '180'},
        {text: 'Kích hoạt OTP', value: 'isConfirmedOTP', width: '70'},
        {text: 'Số SMS đã gửi', value: 'SMSNum', align: 'center', width: '120'},
        {text: 'Hồ sơ đã tạo', value: 'documents', align: 'center'}
      ]
    }
  },
  data: () => ({loading: false, pagination: {}}),
  created() {
    this.loading = true
  },
  methods: {
    ...mapActions({fetchUsers: 'user/fetchUsers'}),
    async getDocuments(item) {
      if (item.cvs.length === 0) return ''
    }
  },
  watch: {
    users() {
      this.loading = false
    },
    pagination: {
      async handler(newOptions, oldOptions) {
        if (newOptions && oldOptions) {
          this.loading = true
          await this.fetchUsers({
            _start: (newOptions.page - 1) * newOptions.itemsPerPage,
            _limit: newOptions.itemsPerPage
          })
          this.loading = false
        }
      },
      immediate: true,
      deep: true
    }
  },
  filters: {
    getUsername: item => get(item, 'username', 'Chưa có thông tin'),
    getCreatedAt: item => (item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss') : 'Chưa có thông tin'),
    getSMSNum: item => get(item, 'SMSNum', 'Chưa có thông tin')
  }
}
</script>
