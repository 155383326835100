<template>
  <div class="flex-center">
    <v-btn color="admin" dark outlined @click="onExport">
      <v-icon left>mdi-file-excel-outline</v-icon>Xuất Excel
    </v-btn>
  </div>
</template>
<script>
import {mapActions} from 'vuex'
import moment from 'moment'
import * as XLSX from 'xlsx'
import {saveAs} from 'file-saver'
import {get} from 'lodash'
export default {
  methods: {
    ...mapActions({fetchCVsIndependent: 'cv/fetchCVsIndependent'}),
    async onExport() {
      this.$loading.active = true
      const cvs = await this.fetchCVsIndependent({
        _sort: 'scholarshipTransaction.transactionTime:DESC',
        type: 'Khối 10',
        populates: 'scholarshipTransaction parent',
        isScholarshipTransactionPaid: true,
        _start: 0,
        _limit: -1
      })
      const worksheet = XLSX.utils.json_to_sheet(
        cvs.map(cv => ({
          'Mã hồ sơ': cv.code,
          'Cơ sở': cv.department,
          'Họ tên học sinh': cv.name,
          'Số điện thoại đăng ký': cv.parentUsername,
          'Đăng ký học bổng lúc': moment(get(cv, 'scholarshipTransaction.transactionTime')).format('DD/MM/YYYY HH:mm')
        }))
      )
      const workbook = {Sheets: {data: worksheet}, SheetNames: ['data']}
      const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'})
      const data = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      })
      saveAs(data, 'ho-so-dk-hb-vnc.xlsx')
      this.$loading.active = false
    }
  }
}
</script>
