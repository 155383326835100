/* eslint-disable no-unused-vars */
import alert from '@/plugins/alert'
import {ActiveCode} from '@/plugins/api'
export default {
  namespaced: true,
  state: {
    ActiveCodes: [],
    count: 0,
    filterParams: {}
  },
  actions: {
    async enableActiveCode(_, code) {
      try {
        alert.success(await ActiveCode.enableActiveCode(code))
      } catch (e) {
        alert.error(e)
      }
    },
    async disableActiveCode(_, code) {
      try {
        alert.success(await ActiveCode.disableActiveCode(code))
      } catch (e) {
        alert.error(e)
      }
    },
    async validate(_, code) {
      try {
        return await ActiveCode.validate(code)
      } catch (e) {
        alert.error(e)
      }
    },
    async fetchActiveCodes({commit, state}, options = {}) {
      const filterParams = {...state.filterParams, ...options}
      commit('setActiveCodes', await ActiveCode.fetch({...filterParams, fromAdmin: true}))
      commit('changeState', {filterParams})
    },
    async countActiveCodes({commit, state}, options = {}) {
      const filterParams = {...state.filterParams, ...options}
      commit('setCount', await ActiveCode.count(filterParams))
    },
    fetchActiveCodesIndependent({state}, options = {}) {
      const filterParams = {...state.filterParams, ...options}
      return ActiveCode.fetch({...filterParams, fromAdmin: true})
    },
    async fetchActiveCode({commit}, code) {
      try {
        const activeCode = await ActiveCode.search({code})
        commit('setActiveCode', activeCode[0])
        return activeCode[0]
      } catch (e) {
        alert.error(e)
      }
    },
    async createActiveCode({commit}, data) {
      try {
        const activeCode = await ActiveCode.create(data)
        commit('setActiveCode', activeCode)
        return activeCode
      } catch (e) {
        alert.error(e)
      }
    },
    async updateActiveCode({commit}, {id, ...activeCode}) {
      try {
        commit('setActiveCode', await ActiveCode.update(id, activeCode))
      } catch (e) {
        alert.error(e)
      }
    },
    async removeActiveCode({commit}, id) {
      try {
        await ActiveCode.remove(id)
        commit('removeActiveCode', id)
        alert.success('Xóa mã hồ sơ thành công!')
      } catch (e) {
        alert.error(e)
      }
    },
    setActiveCode({commit}, ActiveCode) {
      commit('setActiveCode', ActiveCode)
    },
    async removeActiveCodes({dispatch}, items) {
      for (let item of items) {
        await dispatch('removeActiveCode', item.id)
      }
    },
    getDashboardStats(_, params) {
      return ActiveCode.getDashboardStats(params)
    }
  },
  mutations: {
    setActiveCodes(state, ActiveCodes) {
      state.ActiveCodes = ActiveCodes.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [currentValue.id]: currentValue
        }),
        {}
      )
    },
    setActiveCode(state, ActiveCode) {
      state.ActiveCodes = {
        ...state.ActiveCodes,
        [ActiveCode.id]: ActiveCode
      }
    },
    removeActiveCode(state, ActiveCodeId) {
      delete state.ActiveCodes[ActiveCodeId]
      state.ActiveCodes = {...state.ActiveCodes}
    },
    setCount(state, count) {
      state.count = count
    }
  },
  getters: {
    activeCodes: state => Object.values(state.ActiveCodes),
    activeCode: state => id => state.ActiveCodes[id],
    count: state => state.count
  }
}
/* eslint-enable no-unused-vars */
