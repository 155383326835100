<template>
  <div class="pa-6">
    <div class="d-flex justify-space-between align-center mb-6">
      <div class="component-title">Hồ sơ đăng ký học bổng</div>
      <DocumentExcelExport />
    </div>
    <v-card class="pa-6 elevation-1 mb-6">
      <DocumentFilter @onFilterChanged="onFilterChanged" />
    </v-card>

    <v-card class="elevation-1">
      <DocumentTable ref="documentTable" @onDocumentDetail="onDocumentDetail" />
    </v-card>
    <DocumentDetailDialog :state="dialog" :documentId="selectedDocumentId" @closeDialog="dialog = !dialog" />
  </div>
</template>
<script>
import {mapActions} from 'vuex'
import DocumentFilter from './ScholarshipFilter.vue'
import DocumentTable from './ScholarshipTable.vue'
import DocumentDetailDialog from '../DocumentDetailDialog.vue'
import DocumentExcelExport from './ScholarshipExcelExport.vue'
export default {
  components: {DocumentFilter, DocumentTable, DocumentDetailDialog, DocumentExcelExport},
  async mounted() {
    await this.countCVs({
      type: 'Khối 10',
      isScholarshipTransactionPaid: true
    })
  },
  data: () => ({dialog: false, selectedDocumentId: ''}),
  methods: {
    ...mapActions({fetchCVs: 'cv/fetchCVs', countCVs: 'cv/countCVs'}),
    async onFilterChanged(data) {
      this.$loading.active = true
      const params = {
        ...data,
        _sort: 'scholarshipTransaction.transactionTime:DESC',
        type: 'Khối 10',
        populates: 'scholarshipTransaction parent',
        isScholarshipTransactionPaid: true
      }
      await this.fetchCVs({...params, _start: 0, _limit: 10})
      await this.countCVs(params)
      this.$loading.active = false
    },
    onDocumentDetail(data) {
      this.dialog = true
      this.selectedDocumentId = data
    }
  }
}
</script>
<style scoped>
.component-title {
  color: #0d47a1;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}
</style>
