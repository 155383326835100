var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',_vm._b({attrs:{"item-key":"id","headers":_vm.headers,"loading":_vm.loading,"items":_vm.notifications,"items-per-page":10,"disable-sort":"","footer-props":{'items-per-page-text': 'Số thông báo một trang'},"server-items-length":_vm.count,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(items){return [_vm._v(" Thông báo thứ "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" trên tổng "+_vm._s(items.itemsLength)+" thông báo ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.user && item.user.username) || '')+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getCreatedAt")(item))+" ")]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getCreatedBy")(item))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'success')?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])]}},{key:"item.receiver",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getReceiver")(item))+" ")]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"d-flex flex-column align-center justify-center pa-6"},[_c('div',{staticClass:"mt-4 text-subtitle-1"},[_vm._v("Chưa có dữ liệu")])])]},proxy:true},{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"content-wrapper",domProps:{"innerHTML":_vm._s(item.content)}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.status !== 'disabled'),expression:"item.status !== 'disabled'"}],attrs:{"color":"admin","icon":""},on:{"click":function () { return _vm.$emit('on-detail', item); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Xem chi tiết")])])]}}],null,true)},'v-data-table',this.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }