<template>
  <v-row class="align-center">
    <v-col cols="12" md="9">{{ title }}</v-col>
    <v-col class="text-number admin--text text-end" cols="12" md="3">
      {{ value }}
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {title: String, value: Number}
}
</script>
<style scoped>
.text-number {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
}
.card-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #757575;
}
</style>
