<template>
  <div class="flex-center">
    <v-btn color="admin" dark outlined @click="onExport">
      <v-icon left>mdi-file-excel-outline</v-icon>Xuất Excel
    </v-btn>
  </div>
</template>
<script>
import moment from 'moment'
import {mapActions} from 'vuex'
import * as XLSX from 'xlsx'
import {saveAs} from 'file-saver'
import {get} from 'lodash'
export default {
  methods: {
    ...mapActions({fetchUsersForExcel: 'user/fetchUsersForExcel'}),
    async onExport() {
      this.$loading.active = true
      const users = await this.fetchUsersForExcel({})
      const worksheet = XLSX.utils.json_to_sheet(
        users.map(u => ({
          'Số điện thoại': u.username,
          'Ngày tạo': moment(u.createdAt).format('DD/MM/YYYY HH:mm:ss'),
          'Trạng thái': u.isConfirmedOTP ? 'Đã kích hoạt' : 'Chưa kích hoạt',
          'Số SMS đã gửi': u.SMSNum,
          'Hồ sơ đã tạo': get(u, 'ownedCvs', []).join(', ')
        }))
      )
      const workbook = {Sheets: {data: worksheet}, SheetNames: ['data']}
      const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'})
      const data = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      })
      saveAs(data, 'tai-khoan.xlsx')
      this.$loading.active = false
    }
  }
}
</script>
