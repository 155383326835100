<template>
  <div>
    <v-data-table
      item-key="id"
      :headers="headers"
      :loading="loading"
      :items="CVs"
      :items-per-page="10"
      :footer-props="{'items-per-page-text': 'Số Hồ Sơ một trang'}"
      disable-sort
      :server-items-length="count"
      v-bind="this.$attrs"
      :options.sync="pagination"
    >
      <template v-slot:[`footer.page-text`]="items">
        Hồ sơ thứ {{ items.pageStart }} - {{ items.pageStop }} trên tổng {{ items.itemsLength }} Hồ sơ
      </template>
      <template v-slot:[`item.code`]="{item}">
        <v-btn
          class="document-btn text-none text-decoration-underline px-0"
          color="primary"
          @click="onDocumentClick(item.id)"
          plain
        >
          {{ item | getCode }}
        </v-btn>
      </template>
      <template v-slot:[`item.department`]="{item}">
        {{ item | getDepartment }}
      </template>
      <template v-slot:[`item.name`]="{item}">
        {{ item | getStudent }}
      </template>
      <template v-slot:[`item.parentPhone`]="{item}">
        {{ item | getUserPhone }}
      </template>
      <template v-slot:[`item.scholarshipTransactionTime`]="{item}">
        {{ item | getTransactionTime }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {get} from 'lodash'
import moment from 'moment'

export default {
  computed: {
    ...mapGetters('cv', ['CVs', 'count']),
    ...mapGetters('auth', ['user', 'role']),
    headers() {
      return [
        {text: 'Mã hồ sơ', value: 'code'},
        {text: 'Cơ sở', value: 'department'},
        {text: 'Học sinh', value: 'name'},
        {text: 'SĐT tài khoản', value: 'parentPhone'},
        {text: 'Đăng ký lúc', value: 'scholarshipTransactionTime'}
      ]
    }
  },
  data: () => ({loading: false, pagination: {}}),
  watch: {
    pagination: {
      async handler(newOptions, oldOptions) {
        if (newOptions && oldOptions) {
          await this.refresh((newOptions.page - 1) * newOptions.itemsPerPage, newOptions.itemsPerPage)
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...mapActions('cv', ['fetchCVs']),
    getBaseQuery() {
      const query = {
        _sort: 'scholarshipTransaction.transactionTime:DESC',
        type: 'Khối 10',
        populates: 'scholarshipTransaction parent',
        isScholarshipTransactionPaid: true
      }
      if (this.user.department === 'both') query.department_in = ['Cơ sở A', 'Cơ sở 1', 'unset']
      else query.department_in = [this.user.department, 'unset']
      if (!this.user.department || this.user.department !== 'both') query.status_ne = 'disabled'
      return query
    },
    async refresh(_start = 0, _limit = 10) {
      this.loading = true
      await this.fetchCVs({...this.getBaseQuery(), _start, _limit})
      this.loading = false
    },
    onDocumentClick(documentId) {
      this.$emit('onDocumentDetail', documentId)
    }
  },
  filters: {
    getUserPhone: item => get(item, 'parent.username', '---'),
    getStudent: item => get(item, 'name', '---'),
    getDepartment: item => {
      if (!item.department || item.department === 'unset') return '---'
      else return item.department
    },
    getCode: item => get(item, 'code', '---'),
    getTransactionTime: item => moment(get(item, 'scholarshipTransaction.transactionTime')).format('DD/MM/YYYY HH:mm')
  }
}
</script>
