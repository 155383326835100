<template>
  <v-card>
    <v-card-title class="card-title d-flex justify-space-between pa-6">
      Tin nhắn
      <v-progress-circular indeterminate color="primary" v-if="loading" />
    </v-card-title>
    <v-divider />
    <v-card-text class="pa-6">
      <StatTitle title="Tổng số tin nhắn" :value="totalSMS" />
      <StatTitle title="Số tin nhắn trung bình một ngày" :value="dailySMS" />
      <StatTitle title="Số tin nhắn OTP nhưng tài khoản chưa kích hoạt" :value="inactiveSMS" />
    </v-card-text>
  </v-card>
</template>
<script>
import moment from 'moment'
import {mapActions} from 'vuex'
import StatTitle from './StatTitle.vue'

export default {
  components: {StatTitle},
  data: () => ({totalSMS: 0, dailySMS: 0, inactiveSMS: 0, loading: false}),
  methods: {
    ...mapActions({getSmsStats: 'user/getSmsStats'}),
    async refresh(params = {}) {
      this.loading = true
      const res = await this.getSmsStats(params)
      Object.assign(this.$data, res)
      const dayNum = moment().diff(moment('01/01/2025', 'DD/MM/YYYY'), 'days') + 1
      this.dailySMS = Math.abs(Math.round(this.totalSMS / dayNum))
      this.loading = false
    }
  }
}
</script>
<style scoped>
.card-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #757575;
}
</style>
