<template>
  <v-form ref="form" v-bind="this.$attrs">
    <v-divider class="py-2" v-if="$vuetify.breakpoint.smAndDown"></v-divider>
    <v-card-title class="card-title">Kết quả Kỳ thi tuyển sinh vào lớp 10 THPT năm 2025 - 2026</v-card-title>
    <v-card-subtitle class="card-subtitle py-6 mt-4" v-if="!isAdminPreview && document.status !== 'submitted'">
      Phụ huynh vui lòng khai báo đầy đủ thông tin và ấn "Hoàn thành"
    </v-card-subtitle>
    <v-card-text class="d-flex flex-column pa-0">
      <!-- <div class="section-label py-6">Số báo danh</div>
      <v-row class="my-0">
        <v-col class="py-0">
          <div class="field-label">
            Số báo danh dự thi vào kỳ thi vào lớp 10 của Sở GD&ĐT TP. Hà Nội năm 2023
            <span style="color: red" v-if="isEditing">*</span>
          </div>
          <v-text-field
            placeholder="VD: 123123123"
            v-model="ltvExamResult.studentExamID"
            type="number"
            color="primary"
            v-if="isEditing"
            @keyup.enter="submit"
            :rules="[$rules.required]"
            outlined
            validate-on-blur
          />
          <div class="info-label mt-2 mb-6" v-if="!isEditing">
            {{ ltvExamResult.studentExamID || 'Chưa có thông tin' }}
          </div>
        </v-col>
      </v-row>
      <hr class="dashed" /> -->
      <div class="section-label py-6">
        Kết quả kỳ thi tuyển vào lớp 10 của Sở GD&ĐT Hà Nội năm 2024
      </div>
      <v-row class="my-0">
        <v-col class="py-0" cols="12" xs="12" sm="12" md="4">
          <div class="field-label">
            Toán
            <span style="color: red" v-if="isEditing">*</span>
          </div>
          <v-text-field
            placeholder="VD: 10"
            v-model="ltvExamResult.examMath"
            type="number"
            color="primary"
            v-if="isEditing"
            @keyup.enter="submit"
            :rules="[$rules.required, $rules.mark]"
            outlined
            validate-on-blur
          />
          <div class="info-label error--text mt-2 mb-6" style="font-size: 16px" v-if="!isEditing">
            {{ ltvExamResult.examMath | mark }}
          </div>
        </v-col>
        <v-col class="py-0" cols="12" xs="12" sm="12" md="4">
          <div class="field-label">
            Văn
            <span style="color: red" v-if="isEditing">*</span>
          </div>
          <v-text-field
            placeholder="VD: 10"
            v-model="ltvExamResult.examLiterature"
            type="number"
            color="primary"
            v-if="isEditing"
            @keyup.enter="submit"
            :rules="[$rules.required, $rules.mark]"
            outlined
            validate-on-blur
          />
          <div class="info-label error--text mt-2 mb-6" style="font-size: 16px" v-if="!isEditing">
            {{ ltvExamResult.examLiterature | mark }}
          </div>
        </v-col>
        <v-col class="py-0" cols="12" xs="12" sm="12" md="4">
          <div class="field-label">
            Anh
            <span style="color: red" v-if="isEditing">*</span>
          </div>
          <v-text-field
            placeholder="VD: 10"
            v-model="ltvExamResult.examEnglish"
            type="number"
            color="primary"
            v-if="isEditing"
            :rules="[$rules.required, $rules.mark]"
            @keyup.enter="submit"
            outlined
            validate-on-blur
          />
          <div class="info-label error--text mt-2 mb-6" style="font-size: 16px" v-if="!isEditing">
            {{ ltvExamResult.examEnglish | mark }}
          </div>
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col class="py-0" cols="12">
          <div class="field-label">Điểm cộng ưu tiên (nếu có)</div>
          <v-text-field
            placeholder="VD: 3"
            v-model="priorityMark"
            type="number"
            color="primary"
            v-if="isEditing"
            @keyup.enter="submit"
            :rules="[$rules.priorityMark, $rules.required]"
            outlined
            validate-on-blur
          />
          <div class="info-label mt-2 mb-6 error--text" v-if="!isEditing">
            {{ priorityMark || 'Chưa có thông tin' }}
          </div>
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col class="py-0" cols="12">
          <div class="field-label">Điểm xét tuyển</div>
          <v-text-field
            placeholder="VD: 10"
            :value="entranceMark"
            type="number"
            color="primary"
            v-if="isEditing"
            @keyup.enter="submit"
            hide-details
            disabled
            filled
            outlined
            validate-on-blur
          />
          <div class="info-label mt-2 mb-6 error--text" v-if="!isEditing">
            {{ entranceMark || 'Chưa có thông tin' }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
import moment from 'moment'
import {get} from 'lodash'
export default {
  props: {
    documentStep: Number,
    document: {
      type: Object,
      default: () => {}
    },
    systemTime: Object,
    isAdminPreview: Boolean,
    isEditing: Boolean
  },
  computed: {
    getCloseFillDocumentDate() {
      if (this.systemTime.documentSystemTime && this.systemTime.documentSystemTime['close-fill-exam-result']) {
        return `${moment(this.systemTime.documentSystemTime['close-fill-exam-result'], 'DD/MM/YYYY HH:mm:ss').format(
          'HH:mm'
        )} ngày ${moment(this.systemTime.documentSystemTime['close-fill-exam-result'], 'DD/MM/YYYY HH:mm:ss').format(
          'DD/MM/YYYY'
        )}`
      }
      return '00:00 ngày 21/06/2023'
    },
    entranceMark() {
      if (this.isEditing) {
        return (
          Number(get(this.ltvExamResult, 'examMath', '').replace(',', '.')) +
          Number(get(this.ltvExamResult, 'examLiterature', '').replace(',', '.')) +
          Number(get(this.ltvExamResult, 'examEnglish', '').replace(',', '.')) +
          Number(this.priorityMark)
        )
      }
      if (!this.examTotalMark) return 'Chưa có thông tin'
      return this.examTotalMark
    },
    isDevelopmentMode() {
      return process.env.NODE_ENV === 'development'
    },
    isOpenDisplayResult() {
      return (
        (this.ltvExamResult.passExam !== '' &&
          this.systemTime.checkDocumentSystemTime &&
          this.systemTime.checkDocumentSystemTime['display-exam-result']) ||
        (this.isDevelopmentMode && this.ltvExamResult.passExam !== '')
      )
    }
  },
  data() {
    return {
      isValid: false,
      ltvExamResult: {
        examMath: '',
        examLiterature: '',
        examEnglish: '',
      },
      priorityMark: 0,
      agree: false,
      examTotalMark: 0
    }
  },
  created() {
    if (this.document.ltvExamResult) {
      this.ltvExamResult = this.document.ltvExamResult
      if (this.ltvExamResult.priorityMark) {
        let tmp = this.ltvExamResult.priorityMark
        if (typeof this.ltvExamResult.priorityMark === 'string') tmp = tmp.replace(',', '.')
        this.priorityMark = Number(tmp)
      }
      this.agree = this.document.status === 'submitted'
      this.examTotalMark = this.ltvExamResult.examTotalMark
    }
  },
  filters: {
    mark: mark => {
      if (!mark) return 'Chưa có thông tin'
      return mark
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    getPriorityMark() {
      switch (this.ltvExamResult.priorityType) {
        case 'Điểm cộng ưu tiên theo quy định của Sở GD&ĐT Hà Nội':
          this.priorityMark = ''
          break
        case 'Con em chiến sĩ, cán bộ y tế phục vụ chống dịch Covid-19':
          this.priorityMark = 2
          break
        case 'Học sinh THCS Lương Thế Vinh':
          this.priorityMark = 3
          break
        case 'Không có điểm cộng':
          this.priorityMark = 0
          break
      }
    },
    getData() {
      return {
        agree: this.agree,
        ltvExamResult: {
          ...this.ltvExamResult,
          priorityMark: !isNaN(this.priorityMark) ? `${this.priorityMark}` : '0',
          examTotalMark: !isNaN(this.entranceMark) ? `${this.entranceMark}` : '0'
        }
      }
    }
  }
}
</script>

<style scoped>
.mobile-card-title {
  font-size: 22px !important;
}
.field-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #797979;
  margin-bottom: 4px;
}
.section-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #3e3e3c;
}
.info-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3e3e3c;
}
hr.dashed {
  width: 100%;
  border: 1px dashed #e6e4eb;
}
.card-title {
  padding: 0 !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}
.card-subtitle {
  padding: 0 !important;
  margin-top: 0 !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #3e3e3c;
}
.fail-card {
  background: rgba(255, 196, 16, 0.2);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #3e3e3c;
}

@media only screen and (max-width: 400px) {
  .card-title {
    font-size: 20px;
  }
}
@media only screen and (max-width: 350px) {
  .card-title {
    font-size: 17px;
  }
}

@media only screen and (max-width: 500px) and (min-width: 400px) {
  .card-title {
    font-size: 21px;
  }
}
</style>
