<template>
  <v-form>
    <v-row>
      <v-col class="d-flex justify-space-between" cols="12" md="10">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="title_contains" placeholder="Tiêu đề" clearable flat outlined dense hide-details />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="content_contains"
              placeholder="Nội dung"
              clearable
              flat
              outlined
              dense
              hide-details
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="phoneReceiver"
              placeholder="Tài khoản nhận"
              clearable
              flat
              outlined
              dense
              hide-details
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn color="admin" height="40px" width="100%" @click="onFilterChanged" outlined>
          Tìm kiếm
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
export default {
  data: () => ({phoneReceiver: '', title_contains: '', content_contains: ''}),
  methods: {
    onFilterChanged() {
      this.$emit('on-filter-changed', {
        phoneReceiver: this.phoneReceiver,
        title_contains: this.title_contains,
        content_contains: this.content_contains
      })
    }
  }
}
</script>
