<template>
  <div class="pa-6">
    <div class="d-flex justify-space-between align-center mb-6">
      <div class="component-title">Danh sách thông báo</div>
      <v-btn @click="$refs['dialog'].openDialog()" color="primary">+ Soạn thông báo</v-btn>
    </div>
    <div class="d-flex justify-end">
      <ReviewExcelAction class="mr-2" />
      <ResultExcelAction class="mr-2" />
      <ExcelAction />
    </div>
    <v-card class="pa-6 elevation-1 mb-6">
      <NotificationFilter @on-filter-changed="onFilterChanged" />
    </v-card>
    <TableNotification @on-detail="onViewDetail" />
    <CreateNotificationDialog ref="dialog" />
    <NotificationDetailDialog ref="detail-dialog" />
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import TableNotification from './TableNotification.vue'
import CreateNotificationDialog from './CreateNotificationDialog.vue'
import ExcelAction from './ExcelAction.vue'
import ResultExcelAction from './excel-action/ResultExcelAction.vue'
import ReviewExcelAction from './excel-action/ReviewExcelAction.vue'
import NotificationFilter from './NotificationFilter.vue'
import NotificationDetailDialog from './NotificationDetailDialog.vue'
export default {
  components: {
    TableNotification,
    CreateNotificationDialog,
    ExcelAction,
    ResultExcelAction,
    ReviewExcelAction,
    NotificationFilter,
    NotificationDetailDialog
  },
  created() {
    this.countNotis({})
  },
  methods: {
    ...mapActions({fetchNotis: 'notification/fetchNotis', countNotis: 'notification/countNotis'}),
    async onFilterChanged(data = {}) {
      this.$loading.active = true
      await this.fetchNotis({...data, _start: 0, _limit: 10})
      await this.countNotis(data)
      this.$loading.active = false
    },
    onViewDetail(item) {
      this.$refs['detail-dialog'].openDialog(item)
    }
  }
}
</script>

<style scoped>
.component-title {
  color: #0d47a1;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}
</style>
