<template>
  <v-data-table
    item-key="id"
    :headers="headers"
    :loading="loading"
    :items="CVs"
    :items-per-page="10"
    :footer-props="{'items-per-page-text': 'Số Hồ Sơ một trang'}"
    disable-sort
    :server-items-length="count"
    v-bind="this.$attrs"
    :options.sync="pagination"
  >
    <template v-slot:[`footer.page-text`]="items">
      Hồ sơ thứ {{ items.pageStart }} - {{ items.pageStop }} trên tổng {{ items.itemsLength }} Hồ sơ
    </template>
    <template v-slot:[`item.code`]="{item}">
      <v-btn
        class="document-btn text-none text-decoration-underline px-0"
        color="primary"
        @click="() => $emit('onDocumentDetail', item.id)"
        plain
      >
        {{ item | getCode }}
      </v-btn>
    </template>
    <template v-slot:[`item.grade`]="{item}">
      {{ item | getGrade }}
    </template>
    <template v-slot:[`item.department`]="{item}">
      {{ item | getDepartment }}
    </template>
    <template v-slot:[`item.name`]="{item}">
      {{ item | getStudent }}
    </template>
    <template v-slot:[`item.status`]="{item}">
      <v-chip small class="white--text d-flex justify-center" :color="getColor(item)" style="width: 105px" label>
        {{ item | getStatus }}
      </v-chip>
    </template>
    <template v-slot:[`item.parentPhone`]="{item}">
      {{ item | getUserPhone }}
    </template>
    <!-- eslint-disable no-unused-vars--->
    <template v-slot:[`item.action`]="{item}" v-if="user.department === 'both' || user.username === 'annvh'">
      <v-tooltip bottom>
        <template v-slot:activator="{on, attrs}">
          <v-btn
            @click="onActiveItem(item)"
            color="admin"
            v-show="item.status === 'disabled'"
            v-bind="attrs"
            v-on="on"
            icon
          >
            <v-icon> mdi-check </v-icon>
          </v-btn>
        </template>
        <span>Bật hồ sơ</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{on, attrs}">
          <v-btn
            @click="onDeactiveItem(item)"
            color="admin"
            v-show="item.status !== 'disabled'"
            v-bind="attrs"
            v-on="on"
            icon
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </template>
        <span>Tắt hồ sơ</span>
      </v-tooltip>
    </template>
    <!-- eslint-enable no-unused-vars--->
  </v-data-table>
</template>

<script>
/* eslint-disable no-unused-vars */
import {mapActions, mapGetters} from 'vuex'
import {get} from 'lodash'

const ORIGIN_HEADERS = [
  {text: 'Mã hồ sơ', value: 'code'},
  {text: 'Khối', value: 'grade'},
  {text: 'Cơ sở', value: 'department'},
  {text: 'Học sinh', value: 'name'},
  {text: 'Trạng thái', value: 'status'},
  {text: 'SĐT tài khoản', value: 'parentPhone'}
]

export default {
  computed: {
    ...mapGetters('cv', ['CVs', 'CV', 'count']),
    ...mapGetters('auth', ['user']),
    headers() {
      const res = [...ORIGIN_HEADERS]
      if (this.user.department === 'both' || this.user.username === 'annvh')
        res.push({text: 'Hành động', value: 'action', align: 'center'})
      return res
    }
  },
  data: () => ({loading: false, pagination: {}}),
  watch: {
    pagination: {
      async handler(newOptions, oldOptions) {
        if (newOptions && oldOptions) {
          await this.refresh((newOptions.page - 1) * newOptions.itemsPerPage, newOptions.itemsPerPage)
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...mapActions('cv', ['fetchCVs']),
    ...mapActions('activeCode', ['enableActiveCode', 'disableActiveCode']),
    getBaseQuery() {
      let query = {_sort: 'updatedAt:DESC'}
      if (this.user.department === 'both') query.department_in = ['Cơ sở A', 'Cơ sở 1', 'unset']
      else query.department_in = [this.user.department, 'unset']
      if (!this.user.department || this.user.department !== 'both') query.status_ne = 'disabled'
      return query
    },
    async refresh(_start = 0, _limit = 10) {
      this.loading = true
      await this.fetchCVs({...this.getBaseQuery(), _start, _limit})
      this.loading = false
    },
    getColor(item) {
      switch (item.status) {
        case 'submitted':
          return 'success'
        case 'filling':
          return 'orange accent-2'
        case 'disabled':
          return 'gray'
        default:
          return 'red accent-1'
      }
    },
    onDocumentClick(documentId) {
      this.$emit('onDocumentDetail', documentId)
    },
    async onDeactiveItem(item) {
      this.$adminDialog.confirm({
        title: 'Xác nhận hành động',
        okText: 'Xác nhận',
        topContent: `Bạn có chắc chắn muốn tắt hồ sơ này chứ?`,
        midContent: `<span class='error--text'>Lưu ý: Nếu như tắt hồ sơ, phụ huynh học sinh sẽ không thể chỉnh sửa hoặc thao tác trên hồ sơ này được nữa.</span>`,
        cancelText: 'Hủy',
        done: async () => {
          await this.disableActiveCode(item.code)
          await this.refresh({})
        }
      })
    },
    async onActiveItem(item) {
      this.$adminDialog.confirm({
        title: 'Xác nhận hành động',
        okText: 'Xác nhận',
        topContent: `Bạn có chắc chắn muốn bật hồ sơ này chứ?`,
        midContent: `<span class='error--text'>Lưu ý: Nếu như bật lại hồ sơ, phụ huynh học sinh sẽ có thể chỉnh sửa hoặc thao tác trên hồ sơ này.</span>`,
        cancelText: 'Hủy',
        done: async () => {
          await this.enableActiveCode(item.code)
          await this.refresh({})
        }
      })
    },
  },
  filters: {
    getUserPhone: item => get(item, 'parentUsername', '---'),
    getStudent: item => get(item, 'name', '---'),
    getStatus: item => {
      switch (item.status) {
        case 'submitted':
          return 'Hoàn thành'
        case 'filling':
          return 'Đang khai'
        case 'disabled':
          return 'Đang tắt'
        default:
          return 'Chưa khai'
      }
    },
    getDepartment: item => (!item.department || item.department === 'unset' ? '---' : item.department),
    getCode: item => get(item, 'code', '---'),
    getGrade: item => item.type.slice(5)
  }
}
/* eslint-enable no-unused-vars */
</script>
