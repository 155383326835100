<template>
  <div class="pa-6">
    <div class="d-flex justify-space-between align-center">
      <div class="component-title">Báo cáo</div>
      <div class="component-title">{{ getCurrentDepartment }}</div>
    </div>

    <div class="section-title py-6">Mã hồ sơ</div>
    <v-row class="d-flex">
      <v-col cols="6">
        <ActiveCodeStats title="Khối 6" ref="ac-stat-6" />
      </v-col>
      <v-col cols="6">
        <ActiveCodeStats title="Khối 10" ref="ac-stat-10" />
      </v-col>
    </v-row>
    <div class="section-title py-6">Hồ sơ</div>
    <v-row no-gutters>
      <v-card style="width: 100%">
        <v-card-title class="card-title pa-6">Khối 6</v-card-title>
        <v-divider />
        <v-card-text class="pa-6">
          <CvStatByRangeDate type="Khối 6" ref="cv-stat-range-6" />
          <v-row class="d-flex mt-6" no-gutters>
            <v-col cols="7" class="d-flex flex-column align-start" style="border-right: 1px solid #e0e0e0">
              <div class="section-title py-8 px-2">Tình trạng hồ sơ</div>
              <PieChart
                :chartdata="grade6PieChart"
                :options="pieChartOptions"
                class="align-self-center"
                style="width: 315px"
              />
              <div class="text-number align-self-center black--text">
                {{ grade6Document.total }}
              </div>
              <div class="timeline-title align-self-center py-1">
                Hồ sơ đã tạo
              </div>
            </v-col>
            <v-col cols="5" class="d-flex flex-column align-start">
              <div class="section-title py-8 px-6">Hồ sơ đang khai báo</div>
              <v-timeline dense clipped>
                <v-timeline-item fill-dot class="white--text mb-12 pb-0" color="white" small>
                  <template v-slot:icon>
                    <v-icon color="admin"> mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <div>
                    <div class="timeline-title">Chọn sơ sở</div>
                    <div class="timeline-subtitle py-2">Số hồ sơ hoàn tất</div>
                    <div class="text-number admin--text text-start">
                      {{
                        user.department === 'both'
                          ? grade6Document.chooseFacility
                          : user.department === 'Cơ sở A'
                          ? grade6Document.chooseFacilityA
                          : grade6Document.chooseFacility1
                      }}
                    </div>
                    <div class="timeline-subtitle py-2" v-if="user.department === 'both'">
                      Cơ sở A:
                      <span class="admin--text">{{ grade6Document.chooseFacilityA }}</span>
                      / Cơ sở 1:
                      <span class="admin--text">{{ grade6Document.chooseFacility1 }}</span>
                    </div>
                  </div>
                </v-timeline-item>
                <v-timeline-item fill-dot class="white--text mb-12 pb-0" color="white" small>
                  <template v-slot:icon>
                    <v-icon color="admin"> mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <div>
                    <div class="timeline-title">
                      Thông tin phụ huynh
                    </div>
                    <div class="timeline-subtitle py-2">Số hồ sơ hoàn tất</div>
                    <div class="text-number admin--text text-start">
                      {{
                        user.department === 'both'
                          ? grade6Document.infoParent
                          : user.department === 'Cơ sở A'
                          ? grade6Document.infoParentA
                          : grade6Document.infoParent1
                      }}
                    </div>
                    <div class="timeline-subtitle py-2" v-if="user.department === 'both'">
                      Cơ sở A:
                      <span class="admin--text">{{ grade6Document.infoParentA }}</span>
                      / Cơ sở 1:
                      <span class="admin--text">{{ grade6Document.infoParent1 }}</span>
                    </div>
                  </div>
                </v-timeline-item>
                <v-timeline-item fill-dot class="white--text mb-12 pb-0" color="white" small>
                  <template v-slot:icon>
                    <v-icon color="admin"> mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <div>
                    <div class="timeline-title">
                      Thông tin học sinh
                    </div>
                    <div class="timeline-subtitle py-2">Số hồ sơ hoàn tất</div>
                    <div class="text-number admin--text text-start">
                      {{
                        user.department === 'both'
                          ? grade6Document.infoStudent
                          : user.department === 'Cơ sở A'
                          ? grade6Document.infoStudentA
                          : grade6Document.infoStudent1
                      }}
                    </div>
                    <div class="timeline-subtitle py-2" v-if="user.department === 'both'">
                      Cơ sở A:
                      <span class="admin--text">{{ grade6Document.infoStudentA }}</span>
                      / Cơ sở 1:
                      <span class="admin--text">{{ grade6Document.infoStudent1 }}</span>
                    </div>
                  </div>
                </v-timeline-item>
                <v-timeline-item fill-dot class="white--text mb-12 pb-0" color="white" small>
                  <template v-slot:icon>
                    <v-icon color="admin"> mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <div>
                    <div class="timeline-title">Kết quả học tập</div>
                    <div class="timeline-subtitle py-2">Số hồ sơ hoàn tất</div>
                    <div class="text-number admin--text text-start">
                      {{
                        user.department === 'both'
                          ? grade6Document.studyResult
                          : user.department === 'Cơ sở A'
                          ? grade6Document.studyResultA
                          : grade6Document.studyResult1
                      }}
                    </div>
                    <div class="timeline-subtitle py-2" v-if="user.department === 'both'">
                      Cơ sở A:
                      <span class="admin--text">{{ grade6Document.studyResultA }}</span>
                      / Cơ sở 1:
                      <span class="admin--text">{{ grade6Document.studyResult1 }}</span>
                    </div>
                  </div>
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row class="mt-6" no-gutters>
      <v-card style="width: 100%">
        <v-card-title class="card-title pa-6">Khối 10</v-card-title>
        <v-divide />
        <v-card-text class="pa-6">
          <CvStatByRangeDate type="Khối 10" ref="cv-stat-range-10" />
          <v-row class="d-flex mt-6" no-gutters>
            <v-col cols="7" class="d-flex flex-column align-start" style="border-right: 1px solid #e0e0e0">
              <div class="section-title py-8 px-2">Tình trạng hồ sơ</div>
              <PieChart
                :chartdata="grade10PieChart"
                :options="pieChartOptions"
                class="align-self-center"
                style="width: 315px"
              />
              <div class="text-number align-self-center black--text">
                {{ grade10Document.total }}
              </div>
              <div class="timeline-title align-self-center py-1">
                Hồ sơ đã tạo
              </div>
            </v-col>
            <v-col cols="5" class="d-flex flex-column align-start">
              <div class="section-title py-8 px-6">Hồ sơ đang khai báo</div>
              <v-timeline dense clipped>
                <v-timeline-item fill-dot class="white--text mb-12 pb-0" color="white" small>
                  <template v-slot:icon>
                    <v-icon color="admin"> mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <div>
                    <div class="timeline-title">Chọn sơ sở</div>
                    <div class="timeline-subtitle py-2">Số hồ sơ hoàn tất</div>
                    <div class="text-number admin--text text-start">
                      {{
                        user.department === 'both'
                          ? grade10Document.chooseFacility
                          : user.department === 'Cơ sở A'
                          ? grade10Document.chooseFacilityA
                          : grade10Document.chooseFacility1
                      }}
                    </div>
                    <div class="timeline-subtitle py-2" v-if="user.department === 'both'">
                      Cơ sở A:
                      <span class="admin--text">{{ grade10Document.chooseFacilityA }}</span>
                      / Cơ sở 1:
                      <span class="admin--text">{{ grade10Document.chooseFacility1 }}</span>
                    </div>
                  </div>
                </v-timeline-item>
                <v-timeline-item fill-dot class="white--text mb-12 pb-0" color="white" small>
                  <template v-slot:icon>
                    <v-icon color="admin"> mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <div>
                    <div class="timeline-title">
                      Thông tin phụ huynh
                    </div>
                    <div class="timeline-subtitle py-2">Số hồ sơ hoàn tất</div>
                    <div class="text-number admin--text text-start">
                      {{
                        user.department === 'both'
                          ? grade10Document.infoParent
                          : user.department === 'Cơ sở A'
                          ? grade10Document.infoParentA
                          : grade10Document.infoParent1
                      }}
                    </div>
                    <div class="timeline-subtitle py-2" v-if="user.department === 'both'">
                      Cơ sở A:
                      <span class="admin--text">{{ grade10Document.infoParentA }}</span>
                      / Cơ sở 1:
                      <span class="admin--text">{{ grade10Document.infoParent1 }}</span>
                    </div>
                  </div>
                </v-timeline-item>
                <v-timeline-item fill-dot class="white--text mb-12 pb-0" color="white" small>
                  <template v-slot:icon>
                    <v-icon color="admin"> mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <div>
                    <div class="timeline-title">
                      Thông tin học sinh
                    </div>
                    <div class="timeline-subtitle py-2">Số hồ sơ hoàn tất</div>
                    <div class="text-number admin--text text-start">
                      {{
                        user.department === 'both'
                          ? grade10Document.infoStudent
                          : user.department === 'Cơ sở A'
                          ? grade10Document.infoStudentA
                          : grade10Document.infoStudent1
                      }}
                    </div>
                    <div class="timeline-subtitle py-2" v-if="user.department === 'both'">
                      Cơ sở A:
                      <span class="admin--text">{{ grade10Document.infoStudentA }}</span>
                      / Cơ sở 1:
                      <span class="admin--text">{{ grade10Document.infoStudent1 }}</span>
                    </div>
                  </div>
                </v-timeline-item>
                <v-timeline-item fill-dot class="white--text mb-12 pb-0" color="white" small>
                  <template v-slot:icon>
                    <v-icon color="admin"> mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <div>
                    <div class="timeline-title">Kết quả học tập</div>
                    <div class="timeline-subtitle py-2">Số hồ sơ hoàn tất</div>
                    <div class="text-number admin--text text-start">
                      {{
                        user.department === 'both'
                          ? grade10Document.studyResult
                          : user.department === 'Cơ sở A'
                          ? grade10Document.studyResultA
                          : grade10Document.studyResult1
                      }}
                    </div>
                    <div class="timeline-subtitle py-2" v-if="user.department === 'both'">
                      Cơ sở A:
                      <span class="admin--text">{{ grade10Document.studyResultA }}</span>
                      / Cơ sở 1:
                      <span class="admin--text">{{ grade10Document.studyResult1 }}</span>
                    </div>
                  </div>
                </v-timeline-item>
                <v-timeline-item fill-dot class="white--text mb-12 pb-0" color="white" small>
                  <template v-slot:icon>
                    <v-icon color="admin"> mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <div>
                    <div class="timeline-title">Đăng ký nguyện vọng</div>
                    <div class="timeline-subtitle py-2">Số hồ sơ hoàn tất</div>
                    <div class="text-number admin--text text-start">
                      {{
                        user.department === 'both'
                          ? grade10Document.expectation
                          : user.department === 'Cơ sở A'
                          ? grade10Document.expectationA
                          : grade10Document.expectation1
                      }}
                    </div>
                    <div class="timeline-subtitle py-2" v-if="user.department === 'both'">
                      Cơ sở A:
                      <span class="admin--text">{{ grade10Document.expectationA }}</span>
                      / Cơ sở 1:
                      <span class="admin--text">{{ grade10Document.expectationA }}</span>
                    </div>
                  </div>
                </v-timeline-item>
                <v-timeline-item fill-dot class="white--text mb-12 pb-0" color="white" small>
                  <template v-slot:icon>
                    <v-icon color="admin"> mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <div>
                    <div class="timeline-title">Tổ hợp môn học tự chọn</div>
                    <div class="timeline-subtitle py-2">Số hồ sơ hoàn tất</div>
                    <div class="text-number admin--text text-start">
                      {{
                        user.department === 'both'
                          ? grade10Document.groupExpectation
                          : user.department === 'Cơ sở A'
                          ? grade10Document.groupExpectationA
                          : grade10Document.groupExpectation1
                      }}
                    </div>
                    <div class="timeline-subtitle py-2" v-if="user.department === 'both'">
                      Cơ sở A:
                      <span class="admin--text">{{ grade10Document.groupExpectationA }}</span>
                      / Cơ sở 1:
                      <span class="admin--text">{{ grade10Document.groupExpectation1 }}</span>
                    </div>
                  </div>
                </v-timeline-item>
                <v-timeline-item fill-dot class="white--text mb-12 pb-0" color="white" small>
                  <template v-slot:icon>
                    <v-icon color="admin"> mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <div>
                    <div class="timeline-title">Kết quả Kỳ thi tuyển sinh</div>
                    <div class="timeline-subtitle py-2">Số hồ sơ hoàn tất</div>
                    <div class="text-number admin--text text-start">
                      {{
                        user.department === 'both'
                          ? grade10Document.examResult
                          : user.department === 'Cơ sở A'
                          ? grade10Document.examResultA
                          : grade10Document.examResult1
                      }}
                    </div>
                    <div class="timeline-subtitle py-2" v-if="user.department === 'both'">
                      Cơ sở A:
                      <span class="admin--text">{{ grade10Document.examResultA }}</span>
                      / Cơ sở 1:
                      <span class="admin--text">{{ grade10Document.examResult1 }}</span>
                    </div>
                  </div>
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>

<script>
// TODO: split to each component to not block UI
import {mapActions, mapGetters} from 'vuex'
import moment from 'moment'
import PieChart from '@/components/chart/PieChart.vue'
import ActiveCodeStats from '../../modules/dashboard/ActiveCodeStats.vue'
import CvStatByRangeDate from '../../modules/dashboard/CvStatByRangeDate.vue'

export default {
  components: {PieChart, ActiveCodeStats, CvStatByRangeDate},
  computed: {
    ...mapGetters('cv', ['CVs', 'CV', 'count']),
    ...mapGetters('activeCode', ['activeCode', 'activeCodes', 'count']),
    ...mapGetters('auth', ['user']),
    getCurrentDepartment() {
      if (this.user && this.user.department != 'both') return this.user.department
      return 'Cơ sở 1 và Cơ sở A'
    }
  },
  methods: {
    ...mapActions('cv', ['fetchStats']),
    getPieChartData(grade) {
      return {
        labels: ['Hồ sơ chưa khai báo', 'Hồ sơ đang khai báo', 'Hồ sơ đã nộp'],
        datasets: [
          {
            backgroundColor: ['#0D47A1', '#BBDEFB', '#85A7C2'],
            data:
              grade === 'Khối 6'
                ? [
                    this.grade6Document.totalCreated,
                    this.grade6Document.totalFilling,
                    this.grade6Document.totalSubmitted
                  ]
                : [
                    this.grade10Document.totalCreated,
                    this.grade10Document.totalFilling,
                    this.grade10Document.totalSubmitted
                  ]
          }
        ]
      }
    },
    async fetchDocumentStats() {
      const [grade6Document, grade10Document] = await Promise.all([
        this.fetchStats('Khối 6'),
        this.fetchStats('Khối 10')
      ])
      this.grade6Document = grade6Document
      this.grade10Document = grade10Document
    }
  },
  mounted() {
    this.$refs['ac-stat-6'].refresh('Khối 6')
    this.$refs['ac-stat-10'].refresh('Khối 10')

    const initStartDate = moment()
      .utc()
      .clone()
      .startOf('week')
      .toISOString()
    const initEndDate = moment()
      .utc()
      .clone()
      .endOf('week')
      .toISOString()
    this.$refs['cv-stat-range-6'].changeDateRange([initStartDate, initEndDate])
    this.$refs['cv-stat-range-10'].changeDateRange([initStartDate, initEndDate])
  },
  async created() {
    this.$loading.active = true
    if (this.user.department === 'both') {
      this.department = ['Cơ sở 1', 'Cơ sở A', 'unset']
    } else this.department = [this.user.department, 'unset']

    // document stats
    await this.fetchDocumentStats()
    //Grade 6
    this.grade6PieChart = this.getPieChartData('Khối 6')

    // // //Grade 10
    this.grade10PieChart = this.getPieChartData('Khối 10')
    this.$loading.active = false
  },
  data() {
    return {
      pieChartOptions: {responsive: true, maintainAspectRatio: false},
      //Grade 6
      grade6PieChart: {},
      grade6Document: {
        total: 0,
        totalCreated: 0,
        totalSubmitted: 0,
        totalFilling: 0,
        chooseFacility: 0,
        chooseFacility1: 0,
        chooseFacilityA: 0,
        infoForm: 0,
        infoForm1: 0,
        infoFormA: 0,
        studyForm: 0,
        studyForm1: 0,
        studyFormA: 0
      },
      //Grade 10
      grade10PieChart: {},
      grade10Document: {
        total: 0,
        totalCreated: 0,
        totalSubmitted: 0,
        totalFilling: 0,
        chooseFacility: 0,
        chooseFacility1: 0,
        chooseFacilityA: 0,
        infoForm: 0,
        infoForm1: 0,
        infoFormA: 0,
        expectation: 0,
        expectation1: 0,
        expectationA: 0,
        studyForm: 0,
        studyForm1: 0,
        studyFormA: 0,
        examResult: 0,
        examResult1: 0,
        examResultA: 0
      }
    }
  }
}
</script>

<style scoped>
.component-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #0d47a1;
}
.text-number {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
}
.section-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #212121;
}
.card-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #757575;
}
.timeline-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #757575;
}
.timeline-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #757575;
}
</style>
