/* eslint-disable no-unused-vars */
import alert from '@/plugins/alert'
import {CV} from '@/plugins/api'
import moment from 'moment'
export default {
  namespaced: true,
  state: {
    CVs: [],
    count: 0,
    step: 1,
    systemTime: {
      checkSystemTime: {
        'open-document': false,
        'grade6-close-create': false,
        'grade10-close-create': false,
        'grade6-close-submit': false,
        'grade10-close-submit': false
      },
      systemTime: {
        'open-document': moment().format('DD/MM/YYYY HH:mm:ss')
      }
    },
    filterParams: {}
  },
  actions: {
    async checkSystemTime({commit}) {
      try {
        const systemTime = await CV.checkSystemTime()
        commit('setSystemTime', systemTime)
        return systemTime
      } catch (e) {
        alert.error(e)
      }
    },
    async checkDocumentSystemTime(_, {grade}) {
      try {
        return await CV.checkDocumentSystemTime({grade})
      } catch (e) {
        alert.error(e)
      }
    },
    async fetchCVs({commit, state}, options = {}) {
      const filterParams = {...state.filterParams, ...options}
      const cvs = await CV.fetch({...filterParams, fromAdmin: true})
      commit('setCVs', cvs)
      commit('changeState', {filterParams})
      return cvs
    },
    fetchCVsIndependent({state}, options = {}) {
      const filterParams = {...state.filterParams, ...options}
      return CV.fetch({...filterParams, fromAdmin: true})
    },
    async countCVs({commit, state}, options = {}) {
      const filterParams = {...state.filterParams, ...options}
      commit('setCount', await CV.count(filterParams))
    },
    async fetchCV({commit}, {...item}) {
      try {
        const cv = await CV.search({...item})
        if (cv.length > 0) {
          commit('setCV', cv[0])
          commit('setStep', cv[0].step)
          return cv[0]
        }
      } catch (e) {
        alert.error(e)
      }
    },
    fetchStats(_, type) {
      return CV.getDashboardStats(type)
    },
    fetchStatsByDayRange(_, params) {
      return CV.getCountCreateByDayRange(params)
    },
    async createCV({commit}, {code, ...data}) {
      try {
        const newCV = await CV.create(code, data)
        commit('setCV', newCV)
        alert.success('Tạo hồ sơ mới thành công!')
        return newCV
      } catch (e) {
        alert.error(e)
      }
    },
    async updateCV({commit}, {code, ...Cv}) {
      try {
        const updatedCV = await CV.update(code, Cv)
        commit('setCV', updatedCV)
      } catch (e) {
        alert.error(e)
      }
    },
    async removeCV({commit}, id) {
      try {
        const removedCV = await CV.remove(id)
        commit('removeCV', removedCV.code)
        alert.success('Xóa hồ sơ thành công!')
      } catch (e) {
        alert.error(e)
      }
    },
    setCV({commit}, Cv) {
      commit('setCV', Cv)
    },
    setStep({commit}, step) {
      commit('setStep', step)
    },
    async removeCVs({dispatch}, items) {
      for (let item of items) {
        await dispatch('removeCV', item.code)
      }
    },
    setCVs({commit}, cvs) {
      commit('setCVs', cvs)
    }
  },
  mutations: {
    setCVs(state, CVs) {
      state.CVs = CVs.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [`${currentValue.id}`]: currentValue
        }),
        {}
      )
    },
    setCV(state, CV) {
      state.CVs = {
        ...state.CVs,
        [CV.id]: CV
      }
    },
    setStep(state, step) {
      state.step = step
    },
    setSystemTime(state, systemTime) {
      state.systemTime = systemTime
    },
    removeCV(state, id) {
      delete state.CVs[id]
      state.CVs = {...state.CVs}
    },
    setCount(state, count) {
      state.count = count
    }
  },
  getters: {
    systemTime: state => state.systemTime,
    CVs: state => Object.values(state.CVs),
    CV: state => id => state.CVs[id],
    count: state => state.count,
    step: state => state.step
  }
}
/* eslint-enable no-unused-vars */
