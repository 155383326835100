/* eslint-disable no-unused-vars */
import {User} from '@/plugins/api'

export default {
  namespaced: true,
  state: {
    adminUsers: [],
    users: {},
    count: 0,
    filterParams: {}
  },
  actions: {
    async countUsers({commit, state}, options) {
      const res = await User.countUserForAdmin({...state.filterParams, ...options})
      commit('setCount', res)
    },
    async fetchUsers({commit, state}, options) {
      const filterParams = {...state.filterParams, ...options}
      const res = await User.fetchUserForAdmin(filterParams)
      commit('setUsers', res)
      commit('changeState', {filterParams})
    },
    async fetchAdminUsers({commit}, options) {
      const admins = await User.fetch({...options, 'role.type': 'admin'})
      commit('setAdminUsers', admins)
      return admins
    },
    async fetchAdminUser({commit}, userId) {
      commit('setAdminUser', await User.fetchOne(userId))
    },
    getUserStats(_, params) {
      return User.getUserStats(params)
    },
    getSmsStats(_, params) {
      return User.getSmsStats(params)
    },
    fetchUsersForExcel(_, params) {
      return User.fetchUserForExcel({...params, _limit: -1, _sort: 'updatedAt:DESC'})
    }
  },
  mutations: {
    setAdminUsers(state, adminUsers) {
      state.adminUsers = adminUsers.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [currentValue.id]: currentValue
        }),
        {}
      )
    },
    setUsers(state, users) {
      state.users = users.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [currentValue._id]: currentValue
        }),
        {}
      )
    },
    setAdminUser(state, adminUser) {
      state.adminUsers = {
        [adminUser.id]: adminUser,
        ...state.adminUsers
      }
    },
    setCount(state, count) {
      state.count = count
    }
  },
  getters: {
    users: state => Object.values(state.users),
    adminUsers: state => {
      return Object.values(state.adminUsers)
    },
    adminUser: state => id => {
      return state.adminUsers[id]
    },
    count: state => state.count
  }
}
/* eslint-enable no-unused-vars */
