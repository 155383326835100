<template>
  <div class="pa-6">
    <div class="d-flex justify-space-between align-center mb-6">
      <div class="component-title">Quản lý mã hồ sơ</div>
      <ActiveCodeExcelExport />
    </div>
    <v-card class="pa-6 elevation-1 mb-6 d-flex justify-space-around align-center">
      <v-btn color="admin" @click="toggleGrade6Dialog" class="elevation-0" dark
        ><v-icon left>mdi-plus</v-icon>Thêm mã hồ sơ khối 6</v-btn
      >
      <v-divider vertical></v-divider>
      <v-btn color="admin" @click="toggleGrade10Dialog" class="elevation-0" dark
        ><v-icon left>mdi-plus</v-icon>Thêm mã hồ sơ khối 10</v-btn
      >
    </v-card>
    <v-card class="pa-6 elevation-1 mb-6">
      <active-code-filter @onFilterChanged="onFilterChanged" />
    </v-card>

    <v-card class="elevation-1">
      <active-code-table ref="activeCodeTable" />
    </v-card>

    <new-active-code-dialog :state="dialog" :grade="grade" style="margin: 0 20px" @refresh="refresh" />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import NewActiveCodeDialog from './NewActiveCodeDialog.vue'
import ActiveCodeFilter from './ActiveCodeFilter.vue'
import ActiveCodeTable from './ActiveCodeTable.vue'
import ActiveCodeExcelExport from './ActiveCodeExcelExport.vue'

export default {
  components: {NewActiveCodeDialog, ActiveCodeFilter, ActiveCodeTable, ActiveCodeExcelExport},
  data: () => ({dialog: false, grade: 'Khối 6'}),
  computed: {
    ...mapGetters('activeCode', ['activeCodes']),
    ...mapGetters('auth', ['user'])
  },
  async mounted() {
    let query = {_sort: 'updatedAt:DESC'}
    if (this.user.department === 'both') query.department_in = ['Cơ sở A', 'Cơ sở 1', 'unset']
    else {
      query.status_ne = 'disabled'
      query.department_in = [this.user.department, 'unset']
    }
    await this.countActiveCodes(query)
  },
  methods: {
    ...mapActions('activeCode', ['fetchActiveCodes', 'countActiveCodes']),
    refresh() {
      this.$refs.activeCodeTable.refresh()
    },
    toggleLoadingScreen(data) {
      this.$loading.active = data
    },
    toggleGrade6Dialog() {
      this.dialog = !this.dialog
      this.grade = 'Khối 6'
    },
    toggleGrade10Dialog() {
      this.dialog = !this.dialog
      this.grade = 'Khối 10'
    },
    async onFilterChanged(data) {
      this.$loading.active = true
      await this.fetchActiveCodes({
        ...data,
        _sort: 'updatedAt:DESC',
        _start: 0,
        _limit: 10
      })
      await this.countActiveCodes(data)
      this.$loading.active = false
    }
  }
}
</script>

<style scoped>
.component-title {
  color: #0d47a1;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}
</style>
