var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._b({attrs:{"item-key":"id","headers":_vm.headers,"loading":_vm.loading,"items":_vm.CVs,"items-per-page":10,"footer-props":{'items-per-page-text': 'Số Hồ Sơ một trang'},"disable-sort":"","server-items-length":_vm.count,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(items){return [_vm._v(" Hồ sơ thứ "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" trên tổng "+_vm._s(items.itemsLength)+" Hồ sơ ")]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"document-btn text-none text-decoration-underline px-0",attrs:{"color":"primary","plain":""},on:{"click":function () { return _vm.$emit('onDocumentDetail', item.id); }}},[_vm._v(" "+_vm._s(_vm._f("getCode")(item))+" ")])]}},{key:"item.grade",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getGrade")(item))+" ")]}},{key:"item.department",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getDepartment")(item))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getStudent")(item))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text d-flex justify-center",staticStyle:{"width":"105px"},attrs:{"small":"","color":_vm.getColor(item),"label":""}},[_vm._v(" "+_vm._s(_vm._f("getStatus")(item))+" ")])]}},{key:"item.parentPhone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getUserPhone")(item))+" ")]}},(_vm.user.department === 'both' || _vm.user.username === 'annvh')?{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.status === 'disabled'),expression:"item.status === 'disabled'"}],attrs:{"color":"admin","icon":""},on:{"click":function($event){return _vm.onActiveItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-check ")])],1)]}}],null,true)},[_c('span',[_vm._v("Bật hồ sơ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.status !== 'disabled'),expression:"item.status !== 'disabled'"}],attrs:{"color":"admin","icon":""},on:{"click":function($event){return _vm.onDeactiveItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v("Tắt hồ sơ")])])]}}:null],null,true)},'v-data-table',this.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }