<template>
  <div>
    <v-data-table
      item-key="id"
      :headers="headers"
      :loading="loading"
      :items="notifications"
      :items-per-page="10"
      disable-sort
      :footer-props="{'items-per-page-text': 'Số thông báo một trang'}"
      v-bind="this.$attrs"
      :server-items-length="count"
      :options.sync="pagination"
    >
      <template v-slot:[`footer.page-text`]="items">
        Thông báo thứ {{ items.pageStart }} - {{ items.pageStop }} trên tổng {{ items.itemsLength }} thông báo
      </template>
      <template v-slot:[`item.user`]="{item}">
        {{ (item.user && item.user.username) || '' }}
      </template>
      <template v-slot:[`item.createdAt`]="{item}">
        {{ item | getCreatedAt }}
      </template>
      <template v-slot:[`item.createdBy`]="{item}">
        {{ item | getCreatedBy }}
      </template>
      <template v-slot:[`item.status`]="{item}">
        <v-icon color="green" v-if="item.status === 'success'">mdi-check-circle</v-icon>
        <v-icon color="red" v-else>mdi-close</v-icon>
      </template>
      <template v-slot:[`item.receiver`]="{item}">
        {{ item | getReceiver }}
      </template>
      <template v-slot:no-data>
        <div class="d-flex flex-column align-center justify-center pa-6">
          <div class="mt-4 text-subtitle-1">Chưa có dữ liệu</div>
        </div>
      </template>
      <template v-slot:[`item.content`]="{item}">
        <div v-html="item.content" class="content-wrapper" />
      </template>
      <template v-slot:[`item.actions`]="{item}">
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn @click="() => $emit('on-detail', item)" color="admin" v-show="item.status !== 'disabled'" v-bind="attrs" v-on="on" icon>
              <v-icon> mdi-eye </v-icon>
            </v-btn>
          </template>
          <span>Xem chi tiết</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import moment from 'moment'
import {get} from 'lodash'
moment.locale('vi')
const originHeaders = [
  {text: 'Tiêu đề', value: 'title', width: '20%'},
  {text: 'Nội dung', value: 'content', width: '20%'},
  {text: 'Tạo lúc', value: 'createdAt'},
  {text: 'Gửi đến', value: 'receiver'},
  {text: 'Trạng thái', value: 'status'},
  {text: 'Tạo bởi', value: 'createdBy'},
  {text: 'Thao tác', value: 'actions'}
]
export default {
  data: () => ({loading: false, pagination: {}}),
  computed: {
    ...mapGetters({
      notifications: 'notification/notis',
      count: 'notification/count'
    }),
    headers: () => originHeaders
  },
  methods: {
    ...mapActions({fetchNotis: 'notification/fetchNotis'}),
    async refresh(_start = 0, _limit = 10) {
      this.loading = true
      await this.fetchNotis({_start, _limit})
      this.loading = false
    }
  },
  watch: {
    pagination: {
      async handler(newOptions, oldOptions) {
        if (newOptions && oldOptions) {
          await this.refresh((newOptions.page - 1) * newOptions.itemsPerPage, newOptions.itemsPerPage)
        }
      },
      immediate: true,
      deep: true
    }
  },
  filters: {
    getCreatedBy: item => get(item, 'createdBy.name', 'Hệ thống'),
    getCreatedAt(item) {
      if (item.createdAt) return moment(item.createdAt).format('DD/MM/YYYY HH:mm')
      else return '---'
    },
    getReceiver(item) {
      switch (item.receiver) {
        case 'all':
          return 'Tất cả'
        case 'reg':
          return 'Đã đăng ký'
        case 'reg_cv6':
          return 'Đã đăng ký hồ sơ tuyển sinh 6'
        case 'reg_cv10':
          return 'Đã đăng ký hồ sơ tuyển sinh 10'
        case 'single':
          return `Đối tượng cụ thể (${get(item, 'user.username', '')})`
        default:
          return '--'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.content-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
