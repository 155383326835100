<template>
  <v-card>
    <v-card-title class="card-title pa-6 d-flex justify-space-between">
      {{ title }}
      <v-progress-circular indeterminate color="primary" v-if="loading" />
    </v-card-title>
    <v-divider />
    <v-card-text class="d-flex justify-space-between pa-6">
      <div>
        <div class="text-number pb-3 admin--text">
          {{ totalActiveCodes }}
        </div>
        <div>Tổng số mã đã tạo</div>
      </div>
      <div>
        <div class="text-number pb-3 success--text">
          {{ activatedActiveCodes }}
        </div>
        <div>Số mã đã kích hoạt</div>
      </div>
      <div>
        <div class="text-number pb-3 warning--text">
          {{ inactiveActiveCodes }}
        </div>
        <div>Số mã chưa kích hoạt</div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import {mapActions} from 'vuex'
export default {
  props: {title: String},
  data: () => ({totalActiveCodes: '-', inactiveActiveCodes: '-', activatedActiveCodes: '-', loading: true}),
  methods: {
    ...mapActions({getDashboardStats: 'activeCode/getDashboardStats'}),
    async refresh(grade = '') {
      this.loading = true
      const res = await this.getDashboardStats({grade})
      Object.assign(this.$data, res)
      this.loading = false
    }
  }
}
</script>
<style scoped>
.text-number {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
}
</style>
