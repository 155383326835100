<template>
  <div class="flex-center">
    <v-btn color="admin" dark outlined @click="onExport">
      <v-icon left>mdi-file-excel-outline</v-icon>Xuất Excel
    </v-btn>
  </div>
</template>
<script>
import moment from 'moment'
import {mapActions} from 'vuex'
import * as XLSX from 'xlsx'
import {saveAs} from 'file-saver'
export default {
  methods: {
    ...mapActions({fetchActiveCodesIndependent: 'activeCode/fetchActiveCodesIndependent'}),
    renderStatus(value) {
      if (value === 'active') return 'Đã kích hoạt'
      if (value === 'inactive') return 'Chưa kích hoạt'
      return 'Đang tắt'
    },
    async onExport() {
      this.$loading.active = true
      const activeCodes = await this.fetchActiveCodesIndependent({_limit: -1})
      const worksheet = XLSX.utils.json_to_sheet(
        activeCodes.map(ac => ({
          'Mã hồ sơ': ac.code,
          Khối: ac.grade,
          'Cơ sở': ac.department !== 'unset' ? ac.department : '',
          'Trạng thái': this.renderStatus(ac.status),
          'Ngày tạo mã': ac.createdAt ? moment(ac.createdAt).format('DD/MM/YYYY HH:mm:ss') : '',
          'Ngày kích hoạt': ac.activeDate ? moment(ac.activeDate).format('DD/MM/YYYY HH:mm:ss') : '',
          'Tài khoản kích hoạt': ac.userPhone,
          'Số lần in': ac.printNum,
          'Người xuất mã': ac.createdBy
        }))
      )
      const workbook = {Sheets: {data: worksheet}, SheetNames: ['data']}
      const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'})
      const data = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      })
      saveAs(data, 'tai-khoan.xlsx')
      this.$loading.active = false
    }
  }
}
</script>
