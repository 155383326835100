<template>
  <v-card>
    <v-card-title class="card-title d-flex justify-space-between pa-6">
      Tài khoản
      <v-progress-circular indeterminate color="primary" v-if="loading" />
    </v-card-title>
    <v-divider />
    <v-card-text class="pa-6">
      <StatTitle title="Tổng số tài khoản" :value="totalAccount" />
      <StatTitle title="Số tài khoản chưa kích hoạt OTP" :value="totalInactiveAccount" />
      <StatTitle title="Số tài khoản đã kích hoạt OTP chưa có hồ sơ" :value="totalNoDocumentAccount" />
    </v-card-text>
  </v-card>
</template>
<script>
import {mapActions} from 'vuex'
import StatTitle from './StatTitle.vue'

export default {
  components: {StatTitle},
  data: () => ({totalAccount: 0, totalInactiveAccount: 0, totalNoDocumentAccount: 0, loading: false}),
  methods: {
    ...mapActions({getUserStats: 'user/getUserStats'}),
    async refresh(params = {}) {
      this.loading = true
      const res = await this.getUserStats(params)
      Object.assign(this.$data, res)
      this.loading = false
    }
  }
}
</script>
<style scoped>
.card-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #757575;
}
</style>
