<template>
  <v-card width="100%" class="elevation-0">
    <v-divider class="py-2" v-if="$vuetify.breakpoint.smAndDown"></v-divider>
    <v-card-title class="card-title mb-2">
      Đăng ký nguyện vọng vào lớp 10 <br v-if="$vuetify.breakpoint.smAndDown" />THPT năm 2025 - 2026
    </v-card-title>
    <v-card-text class="pa-0">
      <v-form v-model="isValid" ref="expectationForm" v-bind="this.$attrs" style="width: 100%">
        <div class="section-label py-6">Thông tin đăng ký thi vào lớp 10</div>
        <v-row class="my-0">
          <v-col class="py-0">
            <div class="field-label">
              Số lượng nguyện vọng học sinh đã đăng ký vào lớp 10 tại trường công lập năm học 2025 - 2026
              <span style="color: red" v-if="isEditable">*</span>
            </div>
            <v-select
              v-model="expectationNumber"
              placeholder="VD: 1 nguyện vọng"
              :items="[
                {text: '1 nguyện vọng', value: 1},
                {text: '2 nguyện vọng', value: 2},
                {text: '3 nguyện vọng', value: 3}
              ]"
              :rules="[$rules.required]"
              v-if="isEditable"
              outlined
            />
          </v-col>
        </v-row>
        <ExpectationForm
          :isEditable="isEditable"
          :isInformative="isInformative"
          :item="expectation1"
          :itemIndex="1"
          ref="expectation-form-1"
        />
        <ExpectationForm
          v-if="expectationNumber >= 2"
          :isEditable="isEditable"
          :isInformative="isInformative"
          :item="expectation2"
          :itemIndex="2"
          ref="expectation-form-2"
        />
        <ExpectationForm
          v-if="expectationNumber >= 3"
          :isEditable="isEditable"
          :isInformative="isInformative"
          :item="expectation3"
          :itemIndex="3"
          ref="expectation-form-3"
        />
      </v-form>
    </v-card-text>
    <hr class="dashed" />
    <v-card-actions class="d-flex justify-end pt-8 px-0">
      <v-btn
        :class="{'px-6': $vuetify.breakpoint.mdAndUp}"
        class="py-3 mr-6 text-none"
        color="primary"
        v-if="isEditable"
        @click="saveDraft"
        outlined
        large
      >
        <v-icon> mdi-content-save </v-icon>
        <span class="ml-2">Lưu</span>
      </v-btn>
      <v-btn
        :class="{'px-6': $vuetify.breakpoint.mdAndUp}"
        class="py-3 mr-6 text-none"
        color="primary"
        v-if="isUpdatable && !isEditable"
        @click="onEdit"
        outlined
        large
      >
        <span>Chỉnh sửa</span>
      </v-btn>
      <v-btn class="px-6 py-3 text-none elevation-0" color="primary" v-if="isInformative" @click="nextStep" large>
        <span>Tiếp theo</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import ExpectationForm from './Grade10/ExpectationForm.vue'
import moment from 'moment'
const EMPTY_EXPECTATION_DATA = {school: '', district: '', city: ''}
export default {
  components: {ExpectationForm},
  props: {
    document: Object,
    documentStep: Number,
    systemTime: Object,
    isAdminPreview: Boolean,
    isUpdatable: Boolean,
    stepIndex: {
      type: Number,
      default: 3
    }
  },
  created() {
    if (this.document.expectation1 && this.document.expectation1.school) this.expectation1 = this.document.expectation1
    if (this.document.expectation2 && this.document.expectation2.school) {
      this.expectation2 = this.document.expectation2
      this.expectationNumber = 2
    }
    if (this.document.expectation3 && this.document.expectation3.school) {
      this.expectation3 = this.document.expectation3
      this.expectationNumber = 3
    }
    if (this.documentStep > 3) this.isEditing = false
  },
  computed: {
    closeFillInfoTime() {
      if (this.systemTime.documentSystemTime && this.systemTime.documentSystemTime['close-fill-info'])
        return `${moment(this.systemTime.documentSystemTime['close-fill-info'], 'DD/MM/YYYY HH:mm:ss').format(
          'DD/MM/YYYY'
        )} lúc ${moment(this.systemTime.documentSystemTime['close-fill-info'], 'DD/MM/YYYY HH:mm:ss').format('HH:mm')}`
      return '21/06/2023 lúc 00:00'
    },
    isCloseFillInfo() {
      return this.systemTime.checkDocumentSystemTime && this.systemTime.checkDocumentSystemTime['close-fill-info']
    },
    isEditable() {
      return this.documentStep === 3 || this.isEditing
    },
    isInformative() {
      return this.documentStep !== 3 && !this.isEditing
    }
  },
  data() {
    return {
      expectationNumber: 1,
      isValid: false,
      isEditing: true,
      expectation1: {...EMPTY_EXPECTATION_DATA},
      expectation2: {...EMPTY_EXPECTATION_DATA},
      expectation3: {...EMPTY_EXPECTATION_DATA}
    }
  },
  methods: {
    reset() {
      this.$refs.expectationForm.reset()
    },
    getDataWithValidation() {
      let result = {expectation2: {...EMPTY_EXPECTATION_DATA}, expectation3: {...EMPTY_EXPECTATION_DATA}}
      const expectation1 = this.$refs['expectation-form-1'].getData()
      if (expectation1) result.expectation1 = {...expectation1}
      else return false
      if (this.expectationNumber === 3) {
        const expectation3 = this.$refs['expectation-form-3'].getData()
        if (expectation3) result.expectation3 = {...expectation3}
        else return false
        const expectation2 = this.$refs['expectation-form-2'].getData()
        if (expectation2) result.expectation2 = {...expectation2}
        else return false
      } else if (this.expectationNumber === 2) {
        const expectation2 = this.$refs['expectation-form-2'].getData()
        if (expectation2) result.expectation2 = {...expectation2}
        else return false
      }
      return result
    },
    getDataWithoutValidation() {
      let result = {
        expectation1: this.$refs['expectation-form-1'].getData(true),
        expectation2: {...EMPTY_EXPECTATION_DATA},
        expectation3: {...EMPTY_EXPECTATION_DATA}
      }
      if (this.expectationNumber === 3) {
        result.expectation2 = this.$refs['expectation-form-2'].getData(true)
        result.expectation3 = this.$refs['expectation-form-3'].getData(true)
      } else if (this.expectationNumber === 2) {
        result.expectation2 = this.$refs['expectation-form-2'].getData(true)
      }
      return result
    },
    completeStep() {
      const dataToSave = this.getDataWithValidation()
      if (dataToSave) {
        this.$dialog.confirm({
          title: 'Chú ý',
          okText: 'Xác nhận',
          topContent: '',
          midContent: 'Nếu đã chắc chắn quý phụ huynh bấm vào nút xác nhận bên dưới để tiếp tục',
          botContent: '',
          cancelText: 'Kiểm tra lại',
          done: async () => {
            this.$loading.active = true
            this.$emit('completeStep', {...dataToSave})
          }
        })
      }
    },
    saveDraft() {
      // this.$loading.active = true
      const dataToSave = this.getDataWithValidation()
      if (dataToSave) {
        this.$emit('saveDraft', {
          ...dataToSave,
          numberOfExpectations: this.expectationNumber.toString(),
          callback: () => (this.isEditing = false)
        })
      }
    },
    nextStep() {
      this.$loading.active = true
      this.$emit('nextStep')
    },
    onEdit() {
      this.$dialog.confirm({
        title: 'Chỉnh sửa',
        okText: 'Xác nhận',
        topContent: `Phụ huynh lưu ý: Các thông tin này có thể được chỉnh sửa nhưng sẽ bị khóa vào ngày <span class="error--text">${this.closeFillInfoTime}</span>`,
        midContent: 'Nếu đã chắc chắn quý phụ huynh bấm vào nút xác nhận bên dưới để tiếp tục',
        botContent: '',
        cancelText: 'Hủy',
        done: async () => {
          this.isEditing = true
        }
      })
    }
  }
}
</script>
<style scoped>
.card-title {
  padding: 0 !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}
hr.dashed {
  width: 100%;
  border: 1px dashed #e6e4eb;
}
.field-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #797979;
  margin-bottom: 4px;
}
.section-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #3e3e3c;
}

@media only screen and (max-width: 420px) {
  .card-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 350px) {
  .card-title {
    font-size: 17px;
  }
}

@media only screen and (max-width: 500px) and (min-width: 400px) {
  .card-title {
    font-size: 21px;
  }
}
</style>
