var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"d-flex align-center",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","xs":"12","md":"3"}},[_c('div',{staticClass:"section-title px-2"},[_vm._v("Phát sinh hồ sơ")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"12","xs":"12","md":"3"}},[_c('RangeDatePicker',{on:{"change":function (e) { return _vm.changeDateRange(e, _vm.type); }}})],1)],1),_c('div',[(_vm.loading)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('LineChart',{staticStyle:{"height":"275px","width":"100%","position":"'relative'"},attrs:{"chartdata":_vm.chartData,"options":{
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                stepSize: 10,
                reverse: false,
                beginAtZero: true
              }
            }
          ]
        }
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }