<template>
  <div class="flex-center">
    <v-btn color="admin" dark outlined @click="onExport">
      <v-icon left>mdi-file-excel-outline</v-icon>Xuất Excel
    </v-btn>
  </div>
</template>
<script>
import {mapActions} from 'vuex'
import moment from 'moment'
import * as XLSX from 'xlsx'
import {saveAs} from 'file-saver'
import {get} from 'lodash'
export default {
  methods: {
    ...mapActions({fetchCVsIndependent: 'cv/fetchCVsIndependent'}),
    renderCvStatus(val) {
      if (val === 'submitted') return 'Đã nộp'
      if (val === 'created') return 'Vừa tạo'
      if (val === 'disabled') return 'Đang tắt'
      return 'Đang khai'
    },
    renderExpectation(val) {
      if (!val) return ''
      if (val.clazz) return val.clazz
      if (val.school) return val.school
      return ''
    },
    renderTotalScoreG6(cv) {
      if (cv.type === 'Khối 6') {
        const studentProfile = Number(get(cv, 'studyRecord.totalMathLiterature', 0))
        const priority = Number(get(cv, 'ltvExamResult.priorityMark', 0))
        let examMath = Number(get(cv, 'ltvExamResult.examMathMark', 0))
        let examLiterature = Number(get(cv, 'ltvExamResult.examLiterature', 0))
        let examEnglish = Number(get(cv, 'ltvExamResult.examEnglish', 0))
        if (isNaN(examMath)) examMath = 0
        if (isNaN(examLiterature)) examLiterature = 0
        if (isNaN(examEnglish)) examEnglish = 0
        return ((examMath + examLiterature + examEnglish) * 2 + studentProfile + priority).toFixed(2)
      }
      return '---'
    },
    getGroupExpectationChildren(group, prefix = '') {
      if (Array.isArray(group) && group.length) return prefix + group.join(', ')
      return ''
    },
    getGroupExpectation(expectation) {
      let result = []
      const childKHXH = this.getGroupExpectationChildren(get(expectation, 'groupKHXH', []), '- KHXH: ')
      const childKHTN = this.getGroupExpectationChildren(get(expectation, 'groupKHTN', []), '- KHTN: ')
      const childCNNT = this.getGroupExpectationChildren(get(expectation, 'groupCNNT', []), '- CNNT: ')
      if (childKHXH.length) result.push(childKHXH)
      if (childKHTN.length) result.push(childKHTN)
      if (childCNNT.length) result.push(childCNNT)
      if (result.length) return result.join(`\n`)
      return '---'
    },
    renderGroupExpectationG10(cv) {
      if (cv.type === 'Khối 10') {
        let NV1 = this.getGroupExpectation(get(cv, 'ltvExamResult.groupExpectation1'))
        let NV2 = this.getGroupExpectation(get(cv, 'ltvExamResult.groupExpectation2'))
        if (NV1 !== '---') NV1 = '\n' + NV1
        if (NV2 !== '---') NV2 = '\n' + NV2
        return `Nguyện vọng 1: ${NV1}\nNguyện vọng 2: ${NV2}`
      }
      return ''
    },
    async onExport() {
      this.$loading.active = true
      const cvs = await this.fetchCVsIndependent({_start: 0, _limit: -1})
      const worksheet = XLSX.utils.json_to_sheet(
        cvs.map(cv => ({
          'Mã hồ sơ': cv.code,
          'Trạng thái': this.renderCvStatus(cv.status),
          Khối: cv.type.slice(5),
          'Tạo lúc': moment(cv.createdAt).format('DD/MM/YYYY HH:mm:ss'),
          'Cập nhật lúc': moment(cv.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
          'Tài khoản kích hoạt': cv.parentUsername,
          'Cơ sở': cv.department === 'unset' ? 'Chưa có thông tin' : cv.department,
          'Họ và tên': cv.name,
          'Ngày sinh': cv.dob ? moment(cv.dob, 'YYYY-MM-DD').format('DD/MM/YYYY') : '',
          'Giới tính': cv.gender === 'female' ? 'Nữ' : 'Nam',
          'Mã học sinh': cv.studentId,
          'Trường từng học': cv.school,
          'Thành phố': cv.city,
          'Thông tin người khai': [
            `Họ và tên: ${get(cv, 'parentName', '')}`,
            `Số điện thoại: ${get(cv, 'parentPhone', '')}`,
            `Email: ${get(cv, 'parentEmail', '')}`,
            `Số chứng minh thư: ${get(cv, 'parentResidentID', '')}`,
            `Nghề nghiệp: ${get(cv, 'parentJob', '')}`,
            `Địa chỉ: ${get(cv, 'parentAddress', '')}`
          ].join('\n'),
          'Nguyện vọng 1': this.renderExpectation(cv.expectation1),
          'Nguyện vọng 2': this.renderExpectation(cv.expectation2),
          'Nguyện vọng 3': this.renderExpectation(cv.expectation3),
          T1: get(cv, 'studyRecord.grade1Math', ''),
          V1: get(cv, 'studyRecord.grade1Literature', ''),
          A1: get(cv, 'studyRecord.grade1English', ''),
          //
          T2: get(cv, 'studyRecord.grade2Math', ''),
          V2: get(cv, 'studyRecord.grade2Literature', ''),
          A2: get(cv, 'studyRecord.grade2English', ''),
          //
          T3: get(cv, 'studyRecord.grade3Math', ''),
          V3: get(cv, 'studyRecord.grade3Literature', ''),
          A3: get(cv, 'studyRecord.grade3English', ''),
          //
          T4: get(cv, 'studyRecord.grade4Math', ''),
          V4: get(cv, 'studyRecord.grade4Literature', ''),
          A4: get(cv, 'studyRecord.grade4English', ''),
          //
          T5: get(cv, 'studyRecord.grade5Math', ''),
          V5: get(cv, 'studyRecord.grade5Literature', ''),
          A5: get(cv, 'studyRecord.grade5English', ''),
          //
          'Tổng điểm tổng kết toán văn Khối 6': get(cv, 'studyRecord.totalMathLiterature', ''),
          //
          T6: get(cv, 'studyRecord.grade6Math', ''),
          V6: get(cv, 'studyRecord.grade6Literature', ''),
          A6: get(cv, 'studyRecord.grade6English', ''),
          //
          T7: get(cv, 'studyRecord.grade7Math', ''),
          V7: get(cv, 'studyRecord.grade7Literature', ''),
          A7: get(cv, 'studyRecord.grade7English', ''),
          //
          T8: get(cv, 'studyRecord.grade8Math', ''),
          V8: get(cv, 'studyRecord.grade8Literature', ''),
          A8: get(cv, 'studyRecord.grade8English', ''),
          KHTN8: get(cv, 'studyRecord.grade8Physics', ''),
          //
          T9: get(cv, 'studyRecord.grade9Math', ''),
          V9: get(cv, 'studyRecord.grade9Literature', ''),
          A9: get(cv, 'studyRecord.grade9English', ''),
          KHTN9: get(cv, 'studyRecord.grade9Physics', ''),
          //
          'Thành tích nổi bật': get(cv, 'studyRecord.achievements', ''),
          'Diện tuyển thẳng (Khối 10)': get(cv, 'studyRecord.recuitingStraightType', ''),
          'Nguyện vọng Ban (Khối 10)': 'TODO',
          'Số báo danh dự thi': get(cv, 'ltvExamResult.studentExamID', ''),
          'Điểm thi Toán': get(cv, 'ltvExamResult.examMath') || get(cv, 'ltvExamResult.examMathMark'),
          'Điểm thi Văn': get(cv, 'ltvExamResult.examLiterature'),
          'Điểm thi Anh': get(cv, 'ltvExamResult.examEnglish'),
          'Điểm ưu tiên (Khối 6)': get(cv, 'ltvExamResult.priorityMark'),
          'Tổng điểm (khối 6)': this.renderTotalScoreG6(cv),
          'Tổng điểm (khối 10)': get(cv, 'ltvExamResult.entranceMark'),
          'Kết quả trúng tuyển': get(cv, 'ltvExamResult.passExamText')
        }))
      )
      const workbook = {Sheets: {data: worksheet}, SheetNames: ['data']}
      const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'})
      const data = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      })
      saveAs(data, 'ho-so.xlsx')
      this.$loading.active = false
    }
  }
}
</script>
