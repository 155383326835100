<template>
  <div>
    <PrintActiveCode class="d-none" id="printActiveCode" :activeCode="selectedActiveCode" />
    <ConfirmPrintDialog
      :activeCode="selectedActiveCode"
      :state="confirmDialog"
      @closeDialog="confirmDialog = false"
      @onPrint="onPrint"
    />
    <v-data-table
      item-key="id"
      :headers="headers"
      :loading="loading"
      :items="activeCodes"
      :items-per-page="10"
      :disable-sort="$vuetify.breakpoint.smAndDown"
      :footer-props="{'items-per-page-text': 'Số Mã hồ sơ một trang'}"
      v-bind="this.$attrs"
      :server-items-length="count"
      :options.sync="pagination"
    >
      <template v-slot:[`footer.page-text`]="items">
        Mã hồ sơ thứ {{ items.pageStart }} - {{ items.pageStop }} trên tổng {{ items.itemsLength }} Mã hồ sơ
      </template>
      <template v-slot:[`item.code`]="{item}">
        {{ item | getCode }}
      </template>
      <template v-slot:[`item.grade`]="{item}">
        {{ item | getGrade }}
      </template>
      <template v-slot:[`item.department`]="{item}">
        {{ item | getDepartment }}
      </template>
      <template v-slot:[`item.created_at`]="{item}">
        {{ getCreatedAt(item) }}
      </template>
      <template v-slot:[`item.createdBy`]="{item}">
        {{ item | getCreatedBy }}
      </template>
      <template v-slot:[`item.printNum`]="{item}">
        {{ item | getPrintNum }}
      </template>
      <template v-slot:[`item.activeDate`]="{item}">
        {{ getActiveAt(item) }}
      </template>
      <template v-slot:[`item.status`]="{item}">
        <v-chip
          small
          class="white--text d-flex justify-center"
          :color="getColor(item.status)"
          style="width: 105px"
          label
        >
          {{ item.status | getStatus }}
        </v-chip>
      </template>
      <template v-slot:[`item.action`]="{item}">
        <div class="d-flex align-center">
          <v-btn @click="onConfirmPrint(item)" color="admin" icon>
            <v-icon> mdi-printer </v-icon>
          </v-btn>
          <div v-if="user.department === 'both' || user.username === 'annvh'">
            <v-tooltip bottom>
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  @click="onActiveItem(item)"
                  color="admin"
                  v-show="item.status === 'disabled'"
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon> mdi-check </v-icon>
                </v-btn>
              </template>
              <span>Bật mã hồ sơ</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  @click="onDeactiveItem(item)"
                  color="admin"
                  v-show="item.status !== 'disabled'"
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </template>
              <span>Vô hiệu hoá Mã hồ sơ</span>
            </v-tooltip>
          </div>
        </div>
      </template>
      <template v-slot:no-data>
        <div class="d-flex flex-column align-center justify-center pa-6">
          <div class="mt-4 text-subtitle-1">Chưa có dữ liệu</div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
const originHeaders = [
  {text: 'Mã hồ sơ', value: 'code'},
  {text: 'Khối', value: 'grade'},
  {text: 'Cơ sở', value: 'department'},
  {text: 'Ngày tạo mã', value: 'created_at'},
  {text: 'Người tạo mã', value: 'createdBy'},
  {text: 'Số lần in', value: 'printNum'},
  {text: 'Ngày kích hoạt', value: 'activeDate'},
  {text: 'Trạng thái', value: 'status'},
  {text: 'Thao tác', value: 'action', align: 'center'}
]

import ConfirmPrintDialog from './ConfirmPrintDialog.vue'
import PrintActiveCode from './PrintActiveCode.vue'
import {mapActions, mapGetters} from 'vuex'
import {get} from 'lodash'
import moment from 'moment'
moment.locale('vi')

export default {
  components: {PrintActiveCode, ConfirmPrintDialog},
  computed: {
    ...mapGetters('activeCode', ['activeCodes', 'count']),
    ...mapGetters('auth', ['user']),
    headers: () => originHeaders
  },
  data: () => ({loading: false, selectedActiveCode: {}, confirmDialog: false, pagination: {}}),
  methods: {
    ...mapActions('activeCode', ['fetchActiveCodes', 'fetchActiveCode', 'setActiveCode', 'updateActiveCode']),
    ...mapActions('activeCode', ['enableActiveCode', 'disableActiveCode']),
    getCreatedAt(item) {
      if (item.createdAt) return moment(item.createdAt).format('DD/MM/YYYY')
      return '---'
    },
    getActiveAt(item) {
      if (item.activeDate) return moment(item.activeDate).format('DD/MM/YYYY')
      return '---'
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    onConfirmPrint(data) {
      this.selectedActiveCode = data
      this.confirmDialog = true
    },
    async onPrint() {
      this.confirmDialog = false
      this.$loading.active = true
      await this.sleep(250)
      this.$loading.active = false
      this.$htmlToPaper('printActiveCode')
    },
    getBaseQuery() {
      let query = {_sort: 'updatedAt:DESC'}
      if (this.user.department === 'both') query.department_in = ['Cơ sở A', 'Cơ sở 1', 'unset']
      else {
        query.status_ne = 'disabled'
        query.department_in = [this.user.department, 'unset']
      }
      return query
    },
    async refresh(_start = 0, _limit = 10) {
      this.loading = true
      await this.fetchActiveCodes({...this.getBaseQuery(), _start, _limit})
      this.loading = false
    },
    getColor(status) {
      if (status === 'active') return 'green'
      if (status === 'inactive') return 'orange accent-2'
      else return 'gray'
    },
    async onDeactiveItem(item) {
      this.$adminDialog.confirm({
        title: 'Xác nhận hành động',
        okText: 'Xác nhận',
        topContent: `Bạn có chắc chắn muốn vô hiệu hoá Mã hồ sơ ${item.code} không?`,
        midContent: `<span class='error--text'>Lưu ý: Nếu như tắt mã hồ sơ đã được sử dụng, hồ sơ tương ứng cũng sẽ bị vô hiệu hoá (phụ huynh không thể chỉnh sửa).</span>`,
        cancelText: 'Hủy',
        done: async () => {
          await this.disableActiveCode(item.code)
          await this.refresh()
        }
      })
    },
    async onActiveItem(item) {
      this.$adminDialog.confirm({
        title: 'Xác nhận hành động',
        okText: 'Xác nhận',
        topContent: `Bạn có chắc chắn muốn tắt Mã hồ sơ ${item.code} không?`,
        midContent: `<span class='error--text'>Lưu ý: Nếu như bật lại mã hồ sơ đã được sử dụng, hồ sơ tương ứng cũng sẽ được bật lại.</span>`,
        cancelText: 'Hủy',
        done: async () => {
          await this.enableActiveCode(item.code)
          await this.refresh()
        }
      })
    }
  },
  watch: {
    pagination: {
      async handler(newOptions, oldOptions) {
        if (newOptions && oldOptions) {
          await this.refresh((newOptions.page - 1) * newOptions.itemsPerPage, newOptions.itemsPerPage)
        }
      },
      immediate: true,
      deep: true
    }
  },
  filters: {
    getStatus: status => {
      if (status === 'active') return 'Đã kích hoạt'
      if (status === 'inactive') return 'Chưa kích hoạt'
      return 'Đang tắt'
    },
    getDepartment: item => {
      if (!item.department || item.department === 'unset') return '---'
      return item.department
    },
    getCode: item => get(item, 'code', '---'),
    getCreatedBy: item => get(item, 'createdBy', '---'),
    getPrintNum: item => get(item, 'printNum', '0'),
    getGrade: item => item.grade.slice(5)
  }
}
/* eslint-enable no-unused-vars */
</script>
